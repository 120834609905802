import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Pagination from '@material-ui/lab/Pagination'

import './blog.styles.scss'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'

import Card1 from '../../assets/serv-card1.png'
import Card2 from '../../assets/serv-card2.png'
import Card3 from '../../assets/serv-card3.png'
import Card4 from '../../assets/serv-card4.png'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
})

export default function BlogPage() {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }
  return (
    <>
      <InternalPageHero heroClass="blog-hero" title="Blog" />
      <div className="internal-content">
        <Grid container spacing={2}>
          <h1>Coming Soon!</h1>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box component="div" m={1}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={Card1}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Post Title
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Facere sint quos ipsa minima totam praesentium, id numquam,
                      tenetur autem, nihil sunt repellendus in voluptatem aut ut.
                      Nostrum animi ducimus.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box component="div" m={1}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={Card2}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Post Title
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
                      dolorem saepe voluptatibus ut, qui repellendus. Suscipit
                      voluptas illo quidem iste autem, mollitia doloremque. Saepe
                      quod temporibus qui.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box component="div" m={1}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={Card3}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Post Title
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dicta
                      odit id nulla ab tempore molestiae aliquid minima officiis,
                      facilis quisquam itaque dignissimos! Optio adipisci suscipit
                      molestiae eum inventore.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box component="div" m={1}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={Card4}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Post Title
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio
                      libero eligendi iure nisi esse, dolore maiores earum cum. Eos
                      impedit quibusdam quo explicabo alias sint incidunt beatae
                      magni delectus ipsum.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid> */}
        </Grid>
        {/* <Pagination count={10} page={page} onChange={handleChange} /> */}
      </div>
    </>
  )
}
