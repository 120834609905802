import React from 'react'

import './privacy-notice.styles.scss'
import PrivacyContent from '../../assets/privacy-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

const PrivacyNotice = () => {
  return (
    <>
      <InternalPageHero heroClass="about-hero" title="Privacy Notice" />
      <InternalPageContent rightImage={PrivacyContent}>
        <div className="privacy-scroll">
          <h4>INTRODUCTION </h4>
          <p className="int-p">
            Already Inspected (“we” or “us” or “our”) respects the privacy of our
            users (“user” or “you”). This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you visit our website
            Already-Inspected.com, including any other media form, media channel,
            mobile website, or mobile application related or connected thereto
            (collectively, the “Site”). Please read this privacy policy carefully. If
            you do not agree with the terms of this privacy policy, please do not
            access the site.
          </p>
          <p className="int-p">
            We reserve the right to make changes to this Privacy Policy at any time
            and for any reason. We will alert you about any changes by updating the
            “Last Updated” date of this Privacy Policy. Any changes or modifications
            will be effective immediately upon posting the updated Privacy Policy on
            the Site, and you waive the right to receive specific notice of each such
            change or modification.
          </p>
          <p className="int-p">
            You are encouraged to periodically review this Privacy Policy to stay
            informed of updates. You will be deemed to have been made aware of, will
            be subject to, and will be deemed to have accepted the changes in any
            revised Privacy Policy by your continued use of the Site after the date
            such revised Privacy Policy is posted.
          </p>
          <h4>COLLECTION OF YOUR INFORMATION</h4>
          <p className="int-p">
            We may collect information about you in a variety of ways. The
            information we may collect on the Site includes:{' '}
          </p>
          <h6>Personal Data</h6>
          <p className="int-p">
            Personally identifiable information, such as your name, shipping address,
            email address, and telephone number, and demographic information, such as
            your age, gender, hometown, and interests, that you voluntarily give to
            us when you choose to participate in various activities related to the
            Site, such as online chat and message boards. You are under no obligation
            to provide us with personal information of any kind, however your refusal
            to do so may prevent you from using certain features of the Site.
          </p>
          <h6>Derivative Data</h6>
          <p className="int-p">
            Information our servers automatically collect when you access the Site,
            such as your IP address, your browser type, your operating system, your
            access times, and the pages you have viewed directly before and after
            accessing the Site.
          </p>
          <h6>Mobile Device Data</h6>
          <p className="int-p">
            Device information, such as your mobile device ID, model, and
            manufacturer, and information about the location of your device, if you
            access the Site from a mobile device.
          </p>
          <h6>Third-Party Data</h6>
          <p className="int-p">
            Information from third parties, such as personal information or network
            friends, if you connect your account to the third party and grant the
            Site permission to access this information.
          </p>
          <h4>USE OF YOUR INFORMATION</h4>
          <p className="int-p">
            Having accurate information about you permits us to provide you with a
            smooth, efficient, and customized experience. Specifically, we may use
            information collected about you via the Site to:
          </p>
          <ul>
            <li>Administer sweepstakes, promotions, and contests.</li>
            <li>Assist law enforcement and respond to subpoena.</li>
            <li>
              Compile anonymous statistical data and analysis for use internally or
              with third parties.
            </li>
            <li>
              Deliver targeted advertising, coupons, newsletters, and other
              information regarding promotions and the Site to you.
            </li>
            <li>Email you regarding your account or order.</li>
            <li>Enable user-to-user communications.</li>
            <li>
              Generate a personal profile about you to make future visits to the Site
              more personalized.
            </li>
            <li>Increase the efficiency and operation of the Site.</li>
            <li>
              Monitor and analyze usage and trends to improve your experience with
              the Site.
            </li>
            <li>Notify you of updates to the Site.</li>
            <li>Offer new products, services, and/or recommendations to you.</li>
            <li>Perform other business activities as needed.</li>
            <li>
              Prevent fraudulent transactions, monitor against theft, and protect
              against criminal activity.
            </li>
            <li>Process payments and refunds.</li>
            <li>Request feedback and contact you about your use of the Site.</li>
            <li>Resolve disputes and troubleshoot problems.</li>
            <li>Respond to product and customer service requests.</li>
            <li>Send you a newsletter.</li>
          </ul>
          <h4>DISCLOSURE OF YOUR INFORMATION </h4>
          <p className="int-p">
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <h6>By Law or to Protect Rights</h6>
          <p className="int-p">
            If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential violations
            of our policies, or to protect the rights, property, and safety of
            others, we may share your information as permitted or required by any
            applicable law, rule, or regulation. This includes exchanging information
            with other entities for fraud protection and credit risk reduction.
          </p>
          <h6>Third-Party Service Providers</h6>
          <p className="int-p">
            We may share your information with third parties that perform services
            for us or on our behalf, including payment processing, data analysis,
            email delivery, hosting services, customer service, and marketing
            assistance.
          </p>
          <h6>Marketing Communications</h6>
          <p className="int-p">
            With your consent, or with an opportunity for you to withdraw consent, we
            may share your information with third parties for marketing purposes, as
            permitted by law.
          </p>
          <h6>Online Postings </h6>
          <p className="int-p">
            When you post comments, contributions or other content to the Site, your
            posts may be viewed by all users and may be publicly distributed outside
            the Site in perpetuity.
          </p>
          <h6>Third-Party Advertisers</h6>
          <p className="int-p">
            We may use third-party advertising companies to serve ads when you visit
            the Site. These companies may use information about your visits to the
            Site and other websites that are contained in web cookies in order to
            provide advertisements about goods and services of interest to you.
          </p>
          <h6>Other Third Parties</h6>
          <p className="int-p">
            We may share your information with advertisers and investors for the
            purpose of conducting general business analysis. We may also share your
            information with such third parties for marketing purposes, as permitted
            by law.
          </p>
          <p className="int-p">
            We are not responsible for the actions of third parties with whom you
            share personal or sensitive data, and we have no authority to manage or
            control third-party solicitations. If you no longer wish to receive
            correspondence, emails or other communications from third parties, you
            are responsible for contacting the third party directly.
          </p>
          <h4>TRACKING TECHNOLOGIES</h4>
          <h6>Cookies and Web Beacons</h6>
          <p className="int-p">
            We may use cookies, web beacons, tracking pixels, and other tracking
            technologies on the Site to help customize the Site and improve your
            experience. When you access the Site, your personal information is not
            collected through the use of tracking technology. Most browsers are set
            to accept cookies by default. You can remove or reject cookies, but be
            aware that such action could affect the availability and functionality of
            the Site. You may not decline web beacons. However, they can be rendered
            ineffective by declining all cookies or by modifying your web browser’s
            settings to notify you each time a cookie is tendered, permitting you to
            accept or decline cookies on an individual basis.
          </p>
          <h6>Internet-Based Advertising</h6>
          <p className="int-p">
            Additionally, we may use third-party software to serve ads on the Site,
            implement email marketing campaigns, and manage other interactive
            marketing initiatives. This third-party software may use cookies or
            similar tracking technology to help manage and optimize your online
            experience with us. For more information about opting-out of
            interest-based ads, visit the Network Advertising Initiative Opt-Out Tool
            or Digital Advertising Alliance Opt-Out Tool.
          </p>
          <h6>Website Analytics</h6>
          <p className="int-p">
            We may also partner with selected third-party vendors, to allow tracking
            technologies and remarketing services on the Site through the use of
            first party cookies and third-party cookies, to, among other things,
            analyze and track users’ use of the Site, determine the popularity of
            certain content and better understand online activity. By accessing the
            Site, you consent to the collection and use of your information by these
            third-party vendors. You are encouraged to review their privacy policy
            and contact them directly for responses to your questions. We do not
            transfer personal information to these third-party vendors. However, if
            you do not want any information to be collected and used by tracking
            technologies, you can visit the third-party vendor or the Network
            Advertising Initiative Opt-Out Tool or Digital Advertising Alliance
            Opt-Out Tool.
          </p>
          <p className="int-p">
            You should be aware that getting a new computer, installing a new
            browser, upgrading an existing browser, or erasing or otherwise altering
            your browser’s cookies files may also clear certain opt-out cookies,
            plug-ins, or settings.
          </p>
          <h4>THIRD-PARTY WEBSITES</h4>
          <p className="int-p">
            The Site may contain links to third-party websites and applications of
            interest, including advertisements and external services, that are not
            affiliated with us. Once you have used these links to leave the Site, any
            information you provide to these third parties is not covered by this
            Privacy Policy, and we cannot guarantee the safety and privacy of your
            information. Before visiting and providing any information to any
            third-party websites, you should inform yourself of the privacy policies
            and practices (if any) of the third party responsible for that website,
            and should take those steps necessary to, in your discretion, protect the
            privacy of your information. We are not responsible for the content or
            privacy and security practices and policies of any third parties,
            including other sites, services or applications that may be linked to or
            from the Site.
          </p>
          <h4>SECURITY OF YOUR INFORMATION</h4>
          <p className="int-p">
            We use administrative, technical, and physical security measures to help
            protect your personal information. While we have taken reasonable steps
            to secure the personal information you provide to us, please be aware
            that despite our efforts, no security measures are perfect or
            impenetrable, and no method of data transmission can be guaranteed
            against any interception or other type of misuse. Any information
            disclosed online is vulnerable to interception and misuse by unauthorized
            parties. Therefore, we cannot guarantee complete security if you provide
            personal information.
          </p>
          <h4>POLICY FOR CHILDREN</h4>
          <p className="int-p">
            We do not knowingly solicit information from or market to children under
            the age of 13. If you become aware of any data we have collected from
            children under age 13, please contact us using the contact information
            provided below.
          </p>
          <h4>CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
          <h6>Emails and Communications </h6>
          <p className="int-p">
            If you no longer wish to receive correspondence, emails, or other
            communications from us, you may opt-out by:
          </p>
          <ul>
            <li>Contacting us using the contact information provided below</li>
          </ul>
          <p className="int-p">
            If you no longer wish to receive correspondence, emails, or other
            communications from third parties, you are responsible for contacting the
            third party directly.
          </p>
          <h4>CONTACT US</h4>
          <p className="int-p">
            If you have questions or comments about this Privacy Policy, please
            contact us at:
          </p>
          <p className="int-p">
            <a href="tel:917-843-0969"> 917-843-0969</a>
          </p>
          <p className="int-p">
            <a href="mailto:PrivacyTeam@already-inspected.com">
              PrivacyTeam@already-inspected.com
            </a>
          </p>
        </div>
      </InternalPageContent>
    </>
  )
}

export default PrivacyNotice
