import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import CustomButton from '../../components/custom-button/custom-button.component'
import RequestInspectionForm from '../../forms/request-inspection/request-inspection'

import './sellers.styles.scss'
import SellersContent from '../../assets/sellers-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    top: 8,
    bottom: 8,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 3),
    margin: theme.spacing(8, 4),
    overflow: 'auto',
  },
}))

export default function Sellers() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <InternalPageHero heroClass="sellers-hero" title="Sellers" />
      <InternalPageContent rightImage={SellersContent}>
        <p className="int-p">
          You are a For Sale by Owner" (FSBO) who is confident you can sell your home
          without an Agent and save a bundle in commissions. We not only encourage
          you on your decision but we are here to offer you help to do so Fast and
          for Full Price.
        </p>
        <p className="int-p">
          Selling your house can often be a difficult task. You can have numerous
          interested parties yet none of them decide that your home is the right one
          for them. When the time comes when someone is extremely interested, having
          inspection results ruin it all can feel devastating. We’re here to make
          sure that doesn’t happen.
        </p>
        <h3>How We Help Sellers</h3>
        <p className="int-p">
          Many real estate agents and home inspectors feel that there’s often a
          problem with the timing of inspections. They will usually be completed near
          the final stages of the deal, meaning any potential issues could result in
          it completely falling through – or at least lengthening the process.
        </p>
        <p className="int-p">
          With pre-listing inspections through Already Inspected, we’ll completely
          remove this problem from the table. You’ll be able to take care of the
          inspection process before having to deal with the buyer, making everything
          far less complicated.
        </p>
        <h3>Seller Benefits</h3>
        <p className="int-p">
          When the seller benefits, it can make the whole deal go a lot smoother for
          all of the parties involved. A fast and efficient deal is always an
          incredible feeling, so we at Already Inspected strive to help make that
          happen. Here are some benefits of why getting a pre-listing inspection can
          save everyone from a lot of unnecessary stress.
        </p>
        <ul>
          <li className="int-p">
            Any issues that need addressing can be fixed before having to talk to
            buyers, meaning less of a chance there will be a collapse toward the
            final stages of the deal.
          </li>
          <li className="int-p">
            A more informed and happier buyer means a greater chance of sale at the
            price you want.
          </li>
          <li className="int-p">
            The entire process of the purchase is much faster than it would be if the
            inspections took place in the middle of negotiations.
          </li>
          <li className="int-p">
            Less chance of possibly losing out on your buy-side due to it being
            contingent on selling your current home.
          </li>
        </ul>
        <h3>Ready to Sell Your Home Fast and for Full Price?</h3>
        <p className="p-50">
          Having a pre-listing inspection completed with the help of Already
          Inspected can remove a lot of the nerves of a deal falling through. We’re
          ready to help you sell fast and for full price. Contact us today to learn
          more!
        </p>
        <CustomButton onClick={handleOpen}>Request an Inspection</CustomButton>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <RequestInspectionForm />
          </div>
        </Modal>
      </InternalPageContent>
    </>
  )
}
