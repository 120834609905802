import React from 'react'

const InternalPageHero = ({ title, heroClass }) => (
  <div className={heroClass}>
    <div className="containerDiv cont-home-hero">
      <h1>{title}</h1>
    </div>
  </div>
)

export default InternalPageHero
