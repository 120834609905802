import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

import './home.styles.scss'
//import TestimonialsSlider from '../../components/testimonials-slider/testimonials-slider.component'
import Spinner from '../../components/spinner/spinner.component'
import RequestInspectionForm from '../../forms/request-inspection/request-inspection'

import HeroRedCheck from '../../assets/home-hero-check.svg'
import BenefitPath from '../../assets/benefit-path.svg'
import CustomButton from '../../components/custom-button/custom-button.component'
import Benefit1 from '../../assets/noun_House.svg'
import Benefit2 from '../../assets/noun_Real.svg'
import Benefit3 from '../../assets/noun_Real Estate.svg'
import Benefit4 from '../../assets/nameplate.svg'
import InspImg1 from '../../assets/73-Real Estate-Search.svg'
import InspImg2 from '../../assets/94-House-Plan.svg'
import InspImg3 from '../../assets/90-Real Estate-Security.svg'
import InspImg4 from '../../assets/noun_House_1265428.svg'

import { BsArrowRight } from 'react-icons/bs'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    top: 8,
    bottom: 8,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 3),
    margin: theme.spacing(8, 4),
    overflow: 'auto',
  },
}))

const pageReload = () => {
  if (window.localStorage) {
    if (!localStorage.getItem('firstLoad')) {
      localStorage['firstLoad'] = true
      window.location.reload()
    } else localStorage.removeItem('firstLoad')
  }
}

export default function HomePage() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1200)

    pageReload()
  }, [])

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="home-page">
      <div className="hero">
        <img className="img-red-check" src={HeroRedCheck} alt="hero path" />
        <div className="container cont-home-hero">
          <h1>
            Sell Fast
            <br />
            For Full Price
          </h1>
          <p className="p-gray">Let’s get the most out of your sale.</p>
          <Link to="/contact-us">
            <CustomButton isTransparent>
              More Info <BsArrowRight className="icon-arrow-btn" />
            </CustomButton>
          </Link>
        </div>
      </div>
      <div className="benefits">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box component="div" m={1}>
              <img className="benefit-path" src={BenefitPath} alt="Benefit Path" />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <Box component="div" m={1}>
              <h2 className="h2-benefit">
                Benefits of getting a home inspection report instantly
              </h2>
              <Link to="/find-report">
                <CustomButton isLarge>Find an Already-Inspected Home</CustomButton>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box component="div" m={1} className="benefit-box">
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <img className="benefit-img" src={Benefit4} alt="Benefit 1" />
                </Grid>
                <Grid item xs={8}>
                  <p>Remove the Uncertainties When You Sell or Buy Your Home.</p>
                </Grid>
              </Grid>
            </Box>
            <Box component="div" m={1} className="benefit-box">
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <img className="benefit-img" src={Benefit2} alt="Benefit 1" />
                </Grid>
                <Grid item xs={8}>
                  <p>The home can be sold for a satisfying price.</p>
                </Grid>
              </Grid>
            </Box>
            <Box component="div" m={1} className="benefit-box">
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <img className="benefit-img" src={Benefit1} alt="Benefit 2" />
                </Grid>
                <Grid item xs={8}>
                  <p>The entire transaction process is much faster.</p>
                </Grid>
              </Grid>
            </Box>
            <Box component="div" m={1} className="benefit-box">
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <img className="benefit-img" src={Benefit3} alt="Benefit 3" />
                </Grid>
                <Grid item xs={8}>
                  <p>The sale is made with all parties feeling content.</p>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div className="services">
        <Box component="div">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3 className="h3-services">No More Surprises </h3>
              <p className="p-services">
                We’re here to make home inspections easier, eliminating one of the
                most common obstacles that can ruin the completion of a transaction.
              </p>
            </Grid>
          </Grid>
        </Box>
        <Box component="div">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Link to="/buyers">
                <Box component="div" m={1} className="service-card-serv-card1">
                  <h4>Buyers</h4>
                  <div className="red-corner">
                    <BsArrowRight className="arrow-icon" />
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Link to="/sellers">
                <Box component="div" m={1} className="service-card-serv-card2">
                  <h4>Sellers</h4>
                  <div className="red-corner">
                    <BsArrowRight className="arrow-icon" />
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Link to="/realtors">
                <Box component="div" m={1} className="service-card-serv-card3">
                  <h4>Realtors</h4>
                  <div className="red-corner">
                    <BsArrowRight className="arrow-icon" />
                  </div>
                </Box>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}></Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Link to="/inspectors">
                <Box component="div" m={1} className="service-card-serv-card4">
                  <h4>Inspectors</h4>
                  <div className="red-corner">
                    <BsArrowRight className="arrow-icon" />
                  </div>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Link to="/contractors">
                <Box component="div" m={1} className="service-card-serv-card5">
                  <h4>Contractors</h4>
                  <div className="red-corner">
                    <BsArrowRight className="arrow-icon" />
                  </div>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="inspections">
        <Box component="div" m={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={8}>
              <h3>The center of home inspections</h3>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <Box display="flex" flexDirection="row-reverse">
                <CustomButton onClick={handleOpen} isLarge>
                  Request an Inspection Today
                </CustomButton>
                <Modal
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div className={classes.paper}>
                    <RequestInspectionForm />
                  </div>
                </Modal>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box component="div" m={1} className="inspection-cards-wrapper">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <Box component="div" m={1} className="inspection-card">
                <img src={InspImg1} alt="Inspection card 1" />
                <h6>Benefiting the Buyer & Seller</h6>
                <p className="p-font-gray">
                  Buyers get upfront information about the property, leading to
                  faster sales for the seller.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box component="div" m={1} className="inspection-card">
                <img src={InspImg2} alt="Inspection Card 2" />
                <h6>More Sales</h6>
                <p className="p-font-gray">
                  With both parties satisfied, the realtor closes more sales.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box component="div" m={1} className="inspection-card">
                <img src={InspImg3} alt="Inspection Card 3" />
                <h6>More Opportunities</h6>
                <p className="p-font-gray">
                  Home inspectors and contractors have more opportunities to earn
                  through pre-listing inspections, or pre and post-sale repairs.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box component="div" m={1} className="inspection-card">
                <img src={InspImg4} alt="Inspection Card 4" />
                <h6>Better Overall Experience</h6>
                <p className="p-font-gray">
                  Smooth and successful transactions make everyone satisfied with the
                  outcome.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* <div className="testimonials">
        <h5 className="h5-red">Testimonials</h5>
        <TestimonialsSlider />
      </div> */}
    </div>
  )
}
