const InspectionActionTypes = {
    FETCH_INSPECTIONS_START: 'FETCH_INSPECTIONS_START',
    FETCH_INSPECTIONS_SUCCESS: 'FETCH_INSPECTIONS_SUCCESS',
    FETCH_INSPECTIONS_FAILURE: 'FETCH_INSPECTIONS_FAILURE',
    FETCH_INSPECTION_START: 'FETCH_INSPECTION_START',
    FETCH_INSPECTION_SUCCESS: 'FETCH_INSPECTION_SUCCESS',
    FETCH_INSPECTION_FAILURE: 'FETCH_INSPECTION_FAILURE',
    CREATE_INSPECTION_START: 'CREATE_INSPECTION_START',
    CREATE_INSPECTION_SUCCESS: 'CREATE_INSPECTION_SUCCESS',
    CREATE_INSPECTION_FAILURE: 'CREATE_INSPECTION_FAILURE',
    UPDATE_INSPECTION_START: 'UPDATE_INSPECTION_START',
    UPDATE_INSPECTION_SUCCESS: 'UPDATE_INSPECTION_SUCCESS',
    UPDATE_INSPECTION_FAILURE: 'UPDATE_INSPECTION_FAILURE',
    DELETE_INSPECTION_START: 'DELETE_INSPECTION_START',
    DELETE_INSPECTION_SUCCESS: 'DELETE_INSPECTION_SUCCESS',
    DELETE_INSPECTION_FAILURE: 'DELETE_INSPECTION_FAILURE'
}

export default InspectionActionTypes