import React from 'react'
import { NavLink } from 'react-router-dom'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import './nav-menu.component.scss'

const NavMenu = ({ currentUser }) => {
  return (
    <div className="nav-menu">
      <NavLink className="nav-link" activeClassName="is-active" to="/about">
        About
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/find-report">
        Find Report
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/buyers">
        Buyers
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/sellers">
        Sellers
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/realtors">
        Realtors
      </NavLink>
      {currentUser ? (
        <NavLink className="nav-link" activeClassName="is-active" to="/inspectors">
          Inspectors
        </NavLink>
      ) : (
        <NavLink className="nav-link" activeClassName="is-active" to="/directory">
          Directory
        </NavLink>
      )}
      <NavLink className="nav-link" activeClassName="is-active" to="/contractors">
        Contractors
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/blog">
        Blog
      </NavLink>
      <NavLink className="nav-link" activeClassName="is-active" to="/contact-us">
        Contact Us
      </NavLink>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStateToProps)(NavMenu)
