import React from 'react'

import './contractors.styles.scss'
import ContractorsContent from '../../assets/contractors-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

export default function Contractors() {
  return (
    <>
      <InternalPageHero heroClass="contractors-hero" title="Contractors" />
      <InternalPageContent rightImage={ContractorsContent}>
        <p className="int-p">
          At Already Inspected, our goal is to provide everyone involved with the
          home buying process a one-stop location for inspections. This includes the
          sellers, buyers, and realtors, along with the inspectors and contractors.
          Whether you need a service or are providing it, we’re the place you can
          turn to.
        </p>
        <h3>For the Customers </h3>
        <p className="int-p">
          If you’re in need of a contractor for pre or post-sale repairs, we have
          numerous professional businesses available who provide affordable and
          high-quality results.
        </p>
        <h6>[List]</h6>
        <h3>For the Contractors</h3>
        <h4>How We Help Contractors</h4>
        <p className="int-p">
          Being a one-stop location for home inspections streamlines the entire
          process for everyone involved, including you, the contractor. When you’re
          listed on the Already Inspected list of contractors, you’ll have the
          opportunity to gain more business through pre and post-sale repairs. When a
          seller or realtor comes to us for an inspection, we will match them with a
          home inspector from our approved list. Based on the inspection report, we
          will refer them to you for necessary repairs!
        </p>
        <h4>Benefits for Contractors</h4>
        <p className="int-p">
          When sellers and realtors purchase pre-list inspections from us, they’re
          not only speeding up the selling process – they’re being placed on a track
          straight to you as well! When they benefit, you benefit. Here are some
          advantages that you can look forward to.
        </p>
        <ul>
          <li className="int-p">More business</li>
          <li className="int-p">A new way to attract customers to your services</li>
          <li className="int-p">An easier method of getting your company noticed</li>
        </ul>
        <h4>Ready for More Contracting Opportunities?</h4>
        <p className="int-p">
          Are you a contractor looking for a new and unique way to get more busines?
          Working with Already Inspected can help make that happen! Contact us today
          to learn more.
        </p>
      </InternalPageContent>
    </>
  )
}
