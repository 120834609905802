import React from 'react'
import Button from '@material-ui/core/Button'
import './custom-button.styles.scss'

const CustomButton = ({
  children,
  isSecondary,
  isTransparent,
  isLarge,
  isSubmit,
  ...otherProps
}) => (
  <Button
    className={`${
      isSecondary
        ? 'button-secondary'
        : isTransparent
        ? 'button-transparent'
        : isLarge
        ? 'button-large'
        : isSubmit
        ? 'button-submit'
        : ''
    } primary-button`}
    {...otherProps}
  >
    {children}
  </Button>
)

export default CustomButton
