import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import { Link } from 'react-router-dom'
import { fetchInspectionsStartAsync } from '../../redux/inspection/inspection.actions'
import { selectInspectionMap } from '../../redux/inspection/inspection.selector'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Spinner from '../../components/spinner/spinner.component'
import './map.styles.scss'
import MapPin from '../../assets/map-pin.png'
import Forward from '../../assets/forward.png'

function Map({ fetchInspectionsStartAsync }) {
  const [viewport, setViewport] = useState({
    width: '100%',
    height: 600,
    latitude: 37.7577,
    longitude: -95.4376,
    zoom: 3,
  })

  //Reload and Spinner
  const [reload, setReload] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // Filters
  const [stateFilter, setStateFilter] = useState('')
  const [cityFilter, setCityFilter] = useState('')
  const [streetFilter, setStreetFilter] = useState('')
  const [aptNumberFilter, setAptNumberFilter] = useState('')
  const [zipCodeFilter, setZipCodeFilter] = useState('')

  //Data
  const inspectionsMap = useSelector(selectInspectionMap)
  //Popup
  const [showPopup, setShowPopup] = useState({})

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1200)
    setReload(false)
    fetchInspectionsStartAsync()
  }, [fetchInspectionsStartAsync, reload])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid container>
          <Grid item xs={12} md={4} lg={3}>
            <Box component="div" mr={3}>
              <Paper className="search-bar">
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <h6 className="search-bar-title">Search Filters</h6>
                  </Grid>
                  <Grid item style={{ width: 300 }} xs={12}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(inspectionsMap).map(
                        (inspection) => inspection.streetAddress
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Street Address..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setStreetFilter(data)}
                    />
                  </Grid>
                  <Grid item style={{ width: 300 }} xs={12}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(inspectionsMap).map(
                        (inspection) => inspection.aptUnitNumber
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Apt/Unit Number..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setAptNumberFilter(data)}
                    />
                  </Grid>
                  <Grid item style={{ width: 300 }} xs={12}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(inspectionsMap).map(
                        (inspection) => inspection.city
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setCityFilter(data)}
                    />
                  </Grid>
                  <Grid item style={{ width: 300 }} xs={12}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(inspectionsMap).map(
                        (inspection) => inspection.state
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setStateFilter(data)}
                    />
                  </Grid>
                  <Grid item style={{ width: 300, marginBottom: '20px' }} xs={12}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(inspectionsMap).map((inspection) =>
                        inspection.zipCode.toString()
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Zip Code..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setZipCodeFilter(data)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <ReactMapGL
              {...viewport}
              mapStyle="mapbox://styles/israeltorres/ckj8olbcr4vox18o15mz32msg"
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              onViewportChange={setViewport}
            >
              {stateFilter === '' &&
              cityFilter === '' &&
              streetFilter === '' &&
              aptNumberFilter === '' &&
              zipCodeFilter === ''
                ? Object.values(inspectionsMap)
                    .filter(
                      (inspectionsMapLL) =>
                        inspectionsMapLL.latitude !== '' &&
                        inspectionsMapLL.longitude !== ''
                    )
                    .map((inspection) => (
                      <React.Fragment key={inspection.id}>
                        <Marker
                          latitude={inspection.latitude}
                          longitude={inspection.longitude}
                          offsetLeft={-12}
                          offsetTop={-24}
                        >
                          <div
                            onClick={() =>
                              setShowPopup({
                                // ...showPopup,
                                [inspection.id]: true,
                              })
                            }
                          >
                            <img
                              src={MapPin}
                              alt="map pin"
                              className="feather-map-pin"
                              style={{
                                height: `${6 * viewport.zoom}px`,
                                width: `${6 * viewport.zoom}px`,
                              }}
                            />
                          </div>
                        </Marker>
                        {showPopup[inspection.id] ? (
                          <Popup
                            latitude={inspection.latitude}
                            longitude={inspection.longitude}
                            closeButton={true}
                            closeOnClick={false}
                            dynamicPosition={true}
                            onClose={() => setShowPopup({})}
                            anchor="top"
                          >
                            <div className="popup">
                              <p>
                                {inspection.streetAddress} {inspection.aptUnitNumber}
                                , {inspection.city}, {inspection.state}{' '}
                                {inspection.zipCode}
                              </p>
                              <Link
                                className="redirect"
                                to={`/inspectors/${inspection.id}`}
                              >
                                <img
                                  src={Forward}
                                  alt="map pin"
                                  className="feather-map-pin"
                                  style={{
                                    height: 24,
                                    width: 24,
                                  }}
                                />
                              </Link>
                            </div>
                          </Popup>
                        ) : null}
                      </React.Fragment>
                    ))
                : Object.values(inspectionsMap)
                    .filter(
                      (inspection) =>
                        inspection.state === stateFilter ||
                        inspection.city === cityFilter ||
                        inspection.streetAddress === streetFilter ||
                        inspection.aptUnitNumber === aptNumberFilter ||
                        inspection.zipCode === parseInt(zipCodeFilter)
                    )
                    .map((inspection) => (
                      <React.Fragment key={inspection.id}>
                        <Marker
                          latitude={inspection.latitude}
                          longitude={inspection.longitude}
                          offsetLeft={-12}
                          offsetTop={-24}
                        >
                          <div
                            onClick={() =>
                              setShowPopup({
                                // ...showPopup,
                                [inspection.id]: true,
                              })
                            }
                          >
                            <img
                              src={MapPin}
                              alt="map pin"
                              className="feather-map-pin"
                              style={{
                                height: `${6 * viewport.zoom}px`,
                                width: `${6 * viewport.zoom}px`,
                              }}
                            />
                          </div>
                        </Marker>
                        {showPopup[inspection.id] ? (
                          <Popup
                            latitude={inspection.latitude}
                            longitude={inspection.longitude}
                            closeButton={true}
                            closeOnClick={false}
                            dynamicPosition={true}
                            onClose={() => setShowPopup({})}
                            anchor="top"
                          >
                            <div className="popup">
                              <p>
                                {inspection.streetAddress} {inspection.aptUnitNumber}
                                , {inspection.city}, {inspection.state}{' '}
                                {inspection.zipCode}
                              </p>
                              <Link
                                className="redirect"
                                to={`/inspectors/${inspection.id}`}
                              >
                                <img
                                  src={Forward}
                                  alt="map pin"
                                  className="feather-map-pin"
                                  style={{
                                    height: 24,
                                    width: 24,
                                  }}
                                />
                              </Link>
                            </div>
                          </Popup>
                        ) : null}
                      </React.Fragment>
                    ))}
            </ReactMapGL>
          </Grid>
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = createStructuredSelector({})

const mapDispatchToProps = (dispatch) => ({
  fetchInspectionsStartAsync: (...inspectionsMap) =>
    dispatch(fetchInspectionsStartAsync(inspectionsMap)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Map)
