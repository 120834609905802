import React from 'react'

import { SpinnerContainer, SpinnerOverlay } from './spinner.styles'

class Spinner extends React.Component {
  render() {
    return (
      <div>
        <SpinnerOverlay>
          <SpinnerContainer />
        </SpinnerOverlay>
      </div>
    )
  }
}

export default Spinner
