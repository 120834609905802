import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Avatar,
  Grid,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import LinkMui from '@material-ui/core/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import AddressForm from './AddressForm'
import PaymentForm from './PaymentForm'
import Review from './Review'

import './checkout.scss'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paper_dl: {
    height: 140,
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    transition: 'all ease .3s',

    '&:hover': {
      transform: 'translateY(10px)',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  divider: {
    margin: '20px 0',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function Checkout({ report }) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [orderData, setOrderData] = useState({
    address: '',
    city: '',
    country: '',
    email: '',
    verify_email: '',
    firstName: '',
    lastName: '',
    state: '',
    zip: '',
  })

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const next = (data) => {
    if (data.email !== data.verify_email) {
      alert('The email do not match')
    } else {
      setOrderData(data)

      handleNext()
    }
  }

  const steps = ['Billing address', 'Review your order', 'Payment details']

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm next={next} />
      case 1:
        return (
          <Review report={report} handleNext={handleNext} handleBack={handleBack} />
        )
      case 2:
        return (
          <PaymentForm
            orderData={orderData}
            report={report}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )
      default:
        throw new Error('Unknown step')
    }
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Checkout
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              {orderData !== {} ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    Thank you for your purchase:
                  </Typography>
                  <Typography variant="subtitle2">
                    {orderData.firstName} {orderData.lastName}
                  </Typography>
                  <Divider className={classes.divider} />
                  {report.fileUrls.map((url, index) => (
                    <Grid item xs={3} key={index}>
                      <Paper className={classes.paper_dl}>
                        <LinkMui href={url} target="_blank" className="inline-link">
                          <Avatar className="file">
                            <AttachFileIcon className="file-icon" />
                          </Avatar>
                          <p>
                            Download
                            <br />
                            <span className="p-strong">Report</span>
                          </p>
                        </LinkMui>
                      </Paper>
                    </Grid>
                  ))}

                  <br />
                  <Button
                    component={Link}
                    className={classes.button}
                    color="primary"
                    type="button"
                    to="/"
                  >
                    Back to home
                  </Button>
                </>
              ) : (
                <div className={classes.spinner}>
                  <CircularProgress />
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </div>
  )
}
