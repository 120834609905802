import React, { useState } from 'react'
import { connect } from 'react-redux'
import { signUpStart } from '../../redux/user/user.action'
import FileBase from 'react-file-base64'
import swal from 'sweetalert'

import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import './register.scss'

import CustomButton from '../../components/custom-button/custom-button.component'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#de2c2c',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}))

function Register({ signUpStart }) {
  const classes = useStyles()
  const [userCredentials, setUserCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    state: '',
    city: '',
    avatar: '',
    licence: '',
    bio: '',
  })

  const {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phone,
    state,
    city,
    avatar,
    licence,
    bio,
  } = userCredentials

  const clearForm = () => {
    setUserCredentials({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      state: '',
      city: '',
      avatar: '',
      licence: '',
      bio: '',
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (password !== confirmPassword) {
      alert("Password don't match")
      return
    } else if (password.length < 6) {
      alert('Password has to be at least 6 characters')
      return
    } else {
      signUpStart({
        firstName,
        lastName,
        email,
        password,
        phone,
        state,
        city,
        avatar,
        licence,
        bio,
      })

      swal({
        title: 'Sign Up Success!',
        text: 'Welcome to Already Inspected!',
        icon: 'success',
        button: 'Ok',
      })
    }

    clearForm()
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setUserCredentials({ ...userCredentials, [name]: value })
  }

  return (
    <Grid container component="main" className="login">
      <Grid item xs={false} sm={4} md={7} className="image" />
      <Grid item xs={12} sm={8} md={5} elevation={6} className="wrapper">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="firstName"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  type="text"
                  name="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="state"
                  label="State"
                  type="text"
                  name="state"
                  autoComplete="state"
                  value={state}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  type="text"
                  name="city"
                  autoComplete="city"
                  value={city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="licence"
                  label="Licence Number"
                  type="text"
                  name="licence"
                  autoComplete="licence"
                  value={licence}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  minLength="6"
                  id="password"
                  autoComplete="password"
                  value={password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  minLength="6"
                  id="confirmPassword"
                  autoComplete="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FileBase
                  className="file-input"
                  type="file"
                  multiple={false}
                  onDone={({ base64 }) =>
                    setUserCredentials({ ...userCredentials, avatar: base64 })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="bio"
                  label="Enter your bio..."
                  multiline
                  rows={5}
                  type="text"
                  name="bio"
                  autoComplete="bio"
                  value={bio}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <CustomButton type="submit" isSubmit>
              Sign Up
            </CustomButton>
            <Grid container justify="center">
              <Grid item>
                <Link className="redirect" to="/login">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = (dispatch) => ({
  signUpStart: (...userCredentials) => dispatch(signUpStart(userCredentials)),
})

export default connect(null, mapDispatchToProps)(Register)
