import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyB9an5hOicr2r6Mp8Nu7J_QWDA_MqIsy5E',
  authDomain: 'already-inspected.firebaseapp.com',
  databaseURL: 'https://already-inspected.firebaseio.com',
  projectId: 'already-inspected',
  storageBucket: 'already-inspected.appspot.com',
  messagingSenderId: '377508382470',
  appId: '1:377508382470:web:f3bd7fa5ec36214c754087',
}

firebase.initializeApp(config)

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const {
      firstName,
      lastName,
      email,
      phone,
      state,
      city,
      avatar,
      licence,
      bio,
    } = userAuth
    const createdAt = new Date()

    try {
      await userRef.set({
        firstName,
        lastName,
        email,
        phone,
        state,
        city,
        avatar,
        bio,
        licence,
        createdAt,
        ...additionalData,
      })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }

  return userRef
}

export const convertUsersSnapshotToMap = (users) => {
  const transformedUser = users.docs.map((doc) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      state,
      city,
      avatar,
      licence,
      bio,
    } = doc.data()

    return {
      routeName: encodeURI(email.toLowerCase()),
      id: doc.id,
      firstName,
      lastName,
      email,
      phone,
      state,
      city,
      licence,
      avatar,
      bio,
    }
  })

  return transformedUser.reduce((accumulator, user) => {
    accumulator[user.email.toLowerCase()] = user
    return accumulator
  }, {})
}

export const convertInspectionsSnapshotToMap = (inspections) => {
  const transformedInspection = inspections.docs.map((doc) => {
    const {
      streetAddress,
      aptUnitNumber,
      city,
      state,
      zipCode,
      createdAt,
      skuNumber,
      longitude,
      latitude,
      inspectionId,
      userId,
    } = doc.data()

    return {
      routeName: encodeURI(streetAddress.toLowerCase()),
      id: doc.id,
      streetAddress,
      aptUnitNumber,
      city,
      state,
      zipCode,
      createdAt,
      skuNumber,
      longitude,
      latitude,
      inspectionId,
      userId,
    }
  })

  return transformedInspection.reduce((accumulator, inspection) => {
    accumulator[inspection.streetAddress.toLowerCase()] = inspection
    return accumulator
  }, {})
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const inspectionsDb = firestore.collection('inspections')
export const filesDb = firestore.collection('files')
export const storage = firebase.storage()

export default firebase
