import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import './request-inspection.scss'

import CustomButton from '../../components/custom-button/custom-button.component'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#de2c2c',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}))

const RequestInspectionForm = () => {
  const classes = useStyles()
  const [inspectionData, setInspectionData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    inspectorEmail: '',
  })

  const { firstName, lastName, email, phone, role, inspectorEmail } = inspectionData

  const roleOptions = [
    { value: 'Seller', label: 'Seller' },
    { value: 'Realtor', label: 'Realtor' },
  ]

  const clearForm = () => {
    setInspectionData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      inspectorEmail: '',
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    window.open(
      `mailto:${inspectorEmail}?subject=Request Inspection&body=Name: ${firstName} ${lastName}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0ARole: ${role}%0D%0AMessage:%0D%0A&cc=alreadyinspected@gmail.com`
    )

    clearForm()
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setInspectionData({ ...inspectionData, [name]: value })
  }

  return (
    <Grid container component="main" className="login">
      <Grid item xs={12}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FindInPageIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Request Inspection
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="firstName"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  type="text"
                  name="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="role"
                  select
                  label="Select role"
                  value={role}
                  onChange={(e, data) =>
                    setInspectionData({
                      ...inspectionData,
                      role: data.props.value,
                    })
                  }
                  variant="outlined"
                  required
                  fullWidth
                >
                  {roleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="inspectorEmail"
                  label="Inspector Email"
                  type="email"
                  name="inspectorEmail"
                  autoComplete="inspectorEmail"
                  value={inspectorEmail}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <CustomButton type="submit" isSubmit>
              Send Email
            </CustomButton>
            <Grid container justify="center">
              <Grid item>
                <Link className="redirect" to="/directory">
                  Select an Inspector
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default RequestInspectionForm
