import React from 'react'

import './about.styles.scss'
import AboutContent from '../../assets/about-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

export default function About() {
  return (
    <>
      <InternalPageHero heroClass="about-hero" title="About Us" />
      <InternalPageContent rightImage={AboutContent}>
        <p className="int-p">
          Already-Inspected is a service to help make the Home Buying and Selling
          transaction a successful and satisfying experience whereby the status and
          condition of the property contemplated in the transaction is disclosed by
          the Seller by way of a Licensed Inspection Report. The Buyer is able to
          obtain the Inspection Report prior to physically viewing the property and
          be informed well enough to make the right buying decision. No more rushing
          to complete an inspection within a few days of signing an “AS IS”
          Residential Contract For Sale And Purchase, whilst keeping fingers crossed
          as to the disclosures on the Inspection Report. Real Estate professionals
          are coming to the realization that the Inspection comes much too late in
          the home buying/selling transaction cycle. Already-Inspected strives to
          correct and re-order the sequence of activities in this real estate
          transaction by advocating Pre-Listing Inspections. We firmly believe that
          it should be the FIRST step after you decide that you want to sell or buy a
          residential property.
        </p>
        <p className="int-p">
          Having an inspection crush expectation after a contract is signed happens
          all too often and introduces a whole new round of eleventh-hour
          negotiations. This is when frustration, anxiety, and anger begin to seep
          in. In worst cases, the deal is “killed.”
        </p>
        <p className="int-p">
          Already Inspected is a platform for sellers, buyers, and realtors who want
          to avoid the costly pitfalls of not having a pre-listing inspection, and
          for those who want to eliminate one of the most common obstacles
          frustrating the completion of the transaction.
        </p>
        <p className="int-p">
          Our goal is smooth, successful, and satisfactory real estate transactions.
          Please spend some time reviewing our website and learn about all the
          benefits we bring to the industry!
        </p>
      </InternalPageContent>
    </>
  )
}
