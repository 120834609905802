import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import './internal-page-content.styles.scss'
import InternalContentPath from '../../assets/benefit-path.svg'

const InternalPageContent = ({ rightImage, children, ...otherProps }) => (
  <div className="internal-content" {...otherProps}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box component="div" m={1}>
          <img
            className="content-path"
            src={InternalContentPath}
            alt="Internal Content Path"
          />
        </Box>
      </Grid>
    </Grid>
    <Grid container spacing={10}>
      <Grid item sm={12} md={6}>
        <Box component="div" m={1}>
          {children}
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <img className="content-img" src={rightImage} alt="Right Column" />
      </Grid>
    </Grid>
  </div>
)

export default InternalPageContent
