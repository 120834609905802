import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import CustomButton from '../custom-button/custom-button.component'
import NavMenu from '../nav-menu/nav-menu.component'
import NavBrand from '../nav-brand/nav-brand.component'
import MobileSidebar from '../mobile-sidebar/mobile-sidebar.component'

import './navbar.styles.scss'

function Navbar() {
  return (
    <div className="containerDiv">
      <AppBar position="static">
        <Toolbar className="flex-toolbar">
          <div className="flex-menu-brand">
            <MobileSidebar />
            <NavBrand />
          </div>
          <NavMenu />
          <Link to="/find-report" className="top-link">
            <CustomButton isLarge>Find Inspection Reports</CustomButton>
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
