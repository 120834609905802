import React from 'react'

import './find-reports.styles.scss'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalContentPath from '../../assets/benefit-path.svg'
import Map from '../../components/map/map.component'

const FindReports = () => {
  return (
    <>
      <InternalPageHero heroClass="find-reports-hero" title="Find Report" />
      <div className="internal-content">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box component="div" m={1}>
              <img
                className="content-path"
                src={InternalContentPath}
                alt="Internal Content Path"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Box component="div" mb={6} ml={1} mt={3}>
              <p className="last-p">
                Here you can use the search feature to find what you’re looking for.
                If it’s not here, please contact us. We’d love to help!
              </p>
            </Box>
            <Box component="div" m={1}>
              <Map />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default FindReports
