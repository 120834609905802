import { createSelector } from 'reselect'

const selectUser = (state) => state.user
const selectAllUsers = (state) => state.user.usersMap

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
)

export const selectUserMap = createSelector([selectAllUsers], (usersMap) => usersMap)
