import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TextField, Button } from '@material-ui/core'
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  error: {
    color: '#c23d4b',
    margin: '5px 0',
  },
}))

export default function AddressForm({ checkoutToken, next }) {
  const classes = useStyles()
  const methods = useForm({
    defaultValues: {
      address: '',
      city: '',
      country: '',
      email: '',
      verify_email: '',
      firstName: '',
      lastName: '',
      state: '',
      zip: '',
    },
  })

  const controlForm = useFormContext() || {}
  const isError = false

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Billing address
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => next({ ...data }))}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="firstName"
                control={controlForm.control}
                label="First name"
                fullWidth
                autoComplete="given-name"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="lastName"
                control={controlForm.control}
                label="Last name"
                fullWidth
                autoComplete="family-name"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="email"
                control={controlForm.control}
                label="Email Address"
                fullWidth
                autoComplete="shipping email"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="verify_email"
                control={controlForm.control}
                label="Verify Email Address"
                fullWidth
                autoComplete="shipping verify_email"
                error={isError}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                required
                name="address"
                control={controlForm.control}
                label="Street Address"
                fullWidth
                autoComplete="shipping address-line"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="city"
                control={controlForm.control}
                label="City"
                fullWidth
                autoComplete="shipping address-level2"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                name="state"
                control={controlForm.control}
                label="State/Province/Region"
                fullWidth
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="zip"
                control={controlForm.control}
                label="Zip / Postal code"
                fullWidth
                autoComplete="shipping postal-code"
                error={isError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                required
                name="country"
                control={controlForm.control}
                label="Country"
                fullWidth
                autoComplete="shipping country"
                error={isError}
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  )
}
