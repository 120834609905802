import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import './footer.styles.scss'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'

import FooterLogo from '../../assets/footer-logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const Footer = ({ currentUser }) => {
  const classes = useStyles()
  return (
    <div className="footer">
      <Box component="div" m={1} className="footer-row1">
        <Grid container justify="space-between" spacing={3} className={classes.root}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Link to="/">
              <img src={FooterLogo} alt="Footer Logo" />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            {/* <h6 className="h6-footer">Areas We Serve</h6>
            <p className="p-small">
              We proudly serve the entire ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </p> */}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <h6 className="h6-footer">Contact</h6>
            <p className="p-small">
              <span className="span-white">Email:</span>
              <a href="mailto:info@already-inspected.com">
                {' '}
                info@already-inspected.com
              </a>
            </p>
            <p className="p-small">
              <span className="span-white">Office:</span>
              <a href="tel:407-237-9905"> 407-237-9905</a>
            </p>
            <p className="p-small">
              <span className="span-white">Mobile:</span>
              <a href="tel:407-793-7692"> 407-793-7692</a>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        m={1}
        className="footer-row2"
        display="flex"
        alignItems="center"
      >
        <Grid container justify="space-between" spacing={3} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={8} container alignItems="center">
            <Grid
              container
              justify="space-between"
              spacing={3}
              className={classes.root}
            >
              <NavLink className="footer-link" activeClassName="is-active" to="/">
                <span data-hover="Home">Home</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/about"
              >
                <span data-hover="About">About</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/find-reports"
              >
                <span data-hover="Find Reports">Find Reports</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/buyers"
              >
                <span data-hover="Buyers">Buyers</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/sellers"
              >
                <span data-hover="Sellers">Sellers</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/realtors"
              >
                <span data-hover="Realtors">Realtors</span>
              </NavLink>
              {currentUser ? (
                <NavLink
                  className="footer-link"
                  activeClassName="is-active"
                  to="/login"
                >
                  <span data-hover="Inspectors">Inspectors</span>
                </NavLink>
              ) : (
                <NavLink
                  className="footer-link"
                  activeClassName="is-active"
                  to="/directory"
                >
                  <span data-hover="Inspectors">Directory</span>
                </NavLink>
              )}
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/blog"
              >
                <span data-hover="Blog">Blog</span>
              </NavLink>
              <NavLink
                className="footer-link"
                activeClassName="is-active"
                to="/contact-us"
              >
                <span data-hover="Contact Us">Contact Us</span>
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1}>
            <Grid
              container
              justify="space-between"
              spacing={3}
              className={classes.root}
            >
              <Link to="/social-media-url" className="sm-icon-link">
                <FacebookIcon />
              </Link>
              <Link to="/social-media-url" className="sm-icon-link">
                <TwitterIcon />
              </Link>
              <Link to="/social-media-url" className="sm-icon-link">
                <LinkedInIcon />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        m={1}
        className="footer-row3"
        display="flex"
        alignItems="center"
      >
        <Grid container justify="space-between" spacing={3} className={classes.root}>
          <Grid item xs={12} sm={12} md={7} lg={7} container justify="flex-start">
            <p className="p-small">
              Copyright © <Moment format="YYYY">{moment()}</Moment>. Already
              Inspected. All Rights Reserved. |{' '}
              <Link to="/privacy-notice">Privacy Notice</Link> |{' '}
              <Link to="/terms-conditions">Terms and Conditions</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} container justify="flex-end">
            <p className="p-small">
              Website Designed and Maintained by:{' '}
              <a
                href="https://yourdigitalresource.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Digital Resource
              </a>
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStateToProps)(Footer)
