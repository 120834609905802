import InspectionActionTypes from './inspection.types'

import {
  firestore,
  convertInspectionsSnapshotToMap,
  inspectionsDb,
  filesDb,
  storage,
} from '../../firebase/firebase.utils'

// ------ Fetch All Items Actions ------ //
export const fetchInspectionsStart = (inspectionsMap) => ({
  type: InspectionActionTypes.FETCH_INSPECTIONS_START,
})

export const fetchInspectionsSuccess = (inspectionsMap) => ({
  type: InspectionActionTypes.FETCH_INSPECTIONS_SUCCESS,
  payload: inspectionsMap,
})

export const fetchInspectionsFailure = (errorMessage) => ({
  type: InspectionActionTypes.FETCH_INSPECTIONS_FAILURE,
  payload: errorMessage,
})

export const fetchInspectionsStartAsync = () => {
  return (dispatch) => {
    const inspectionRef = firestore.collection('inspections')
    dispatch(fetchInspectionsStart())

    inspectionRef
      .get()
      .then((snapshop) => {
        const inspectionsMap = convertInspectionsSnapshotToMap(snapshop)
        dispatch(fetchInspectionsSuccess(inspectionsMap))
      })
      .catch((error) => dispatch(fetchInspectionsFailure(error.message)))
  }
}

// ------ Fetch Item by Id Actions ------ //
export const fetchInspectionByIdStart = (id) => ({
  type: InspectionActionTypes.FETCH_INSPECTION_START,
})

export const fetchInspectionByIdSuccess = (id) => async (dispatch) => {
  const inspectionRef = inspectionsDb.doc(id)

  try {
    // Get inspection items
    await inspectionRef.get().then((doc) => {
      if (!doc.exists) {
        console.log('No such document!')
      } else {
        console.log('Document data:', doc.data())
        // Get files urls
        //Create var to pass in payload
        const fileUrls = []
        filesDb
          .where('inspectionId', '==', doc.data().inspectionId)
          .get()
          .then((files) => {
            files.forEach((file) => {
              if (!file.exists) {
                console.log('No such file!')
              } else {
                console.log('Files urls', file.id, '=>', file.data().url)

                // Get files storage urls
                const starsRef = storage.refFromURL(file.data().url)
                // Get the download URL
                starsRef
                  .getDownloadURL()
                  .then(function (dUrl) {
                    fileUrls.push(dUrl)
                  })
                  .catch(function (error) {
                    switch (error.code) {
                      case 'storage/object-not-found':
                        // File doesn't exist
                        break
                      case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break
                      case 'storage/canceled':
                        // User canceled the upload
                        break
                      case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break
                      default:
                        return error
                    }
                  })

                // Create var to pass in payload
                const docData = doc.data()

                dispatch({
                  type: InspectionActionTypes.FETCH_INSPECTION_START,
                  payload: { docData, fileUrls },
                })
              }
            })
          })
      }
    })
  } catch (error) {
    console.log('error fetching inspection item', error.message)
  }
}

export const fetchInspectionByIdFailure = (errorMessage) => ({
  type: InspectionActionTypes.FETCH_INSPECTION_START,
  payload: errorMessage,
})

// ------ Create Actions ------ //
export const createInspectionsStart = () => ({
  type: InspectionActionTypes.CREATE_INSPECTION_START,
})

export const createInspectionsSuccess = (inspectionItem) => async (dispatch) => {
  const inspectionRef = firestore.collection('inspections')
  const snapShot = await inspectionRef
    .where('skuNumber', '==', `${inspectionItem[5]}`)
    .get()
  if (snapShot.empty) {
    try {
      const inspection = await inspectionsDb.add({
        streetAddress: inspectionItem[0],
        aptUnitNumber: inspectionItem[1],
        city: inspectionItem[2],
        state: inspectionItem[3],
        zipCode: inspectionItem[4],
        skuNumber: inspectionItem[5],
        latitude: inspectionItem[6],
        longitude: inspectionItem[7],
        createdAt: inspectionItem[8],
        inspectionId: inspectionItem[9],
        userId: inspectionItem[10],
      })
      dispatch({
        type: InspectionActionTypes.CREATE_INSPECTION_SUCCESS,
        payload: inspection,
      })
    } catch (error) {
      console.log('error creating inspection', error.message)
    }
  } else {
    alert('This SKU number already exists in the database. Please try again!')
  }
}

export const createInspectionsFailure = (errorMessage) => ({
  type: InspectionActionTypes.CREATE_INSPECTION_FAILURE,
  payload: errorMessage,
})

// ------ Update Actions ------ //
export const updateInspectionStart = (inspectionItem) => ({
  type: InspectionActionTypes.UPDATE_INSPECTION_START,
})

export const updateInspectionSuccess = (inspectionItem) => async (dispatch) => {
  const inspectionRef = inspectionsDb.doc(`${inspectionItem[0]}`)
  try {
    const inspection = await inspectionRef.update({
      streetAddress: inspectionItem[1],
      aptUnitNumber: inspectionItem[2],
      city: inspectionItem[3],
      state: inspectionItem[4],
      zipCode: inspectionItem[5],
      skuNumber: inspectionItem[6],
      latitude: inspectionItem[7],
      longitude: inspectionItem[8],
      createdAt: inspectionItem[9],
      userId: inspectionItem[10],
    })
    dispatch({
      type: InspectionActionTypes.UPDATE_INSPECTION_SUCCESS,
      payload: inspection,
    })
  } catch (error) {
    console.log('error creating inspection', error.message)
  }
}

export const updateInspectionFailure = (errorMessage) => ({
  type: InspectionActionTypes.UPDATE_INSPECTION_FAILURE,
  payload: errorMessage,
})

// ------ Delete Actions ------ //
export const deleteInspectionStart = (id, inspectionId) => ({
  type: InspectionActionTypes.DELETE_INSPECTIONS_START,
})

export const deleteInspectionSuccess = (id, inspectionId) => async (dispatch) => {
  const inspectionRef = firestore.collection('inspections')
  const filesRef = firestore.collection('files')
  //const inspectionFiles = storage.ref('files/') // TODO: create logic to remove storage files

  try {
    console.log(inspectionId)
    await inspectionRef.doc(`${id}`).delete()

    await filesRef
      .where('inspectionId', '==', `${inspectionId}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log('Files successfully deleted!')
            })
            .catch(function (error) {
              console.error('Error removing files: ', error)
            })
        })
      })
      .catch(function (error) {
        console.log('Error getting files: ', error)
      })

    dispatch({
      type: InspectionActionTypes.DELETE_INSPECTION_SUCCESS,
      payload: { id, inspectionId },
    })
  } catch (error) {
    console.log('error removing inspection item', error.message)
  }
}

export const deleteInspectionFailure = (errorMessage) => ({
  type: InspectionActionTypes.DELETE_INSPECTION_FAILURE,
  payload: errorMessage,
})
