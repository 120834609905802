import UserActionTypes from './user.types'
import { firestore, convertUsersSnapshotToMap } from '../../firebase/firebase.utils'

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
})

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
})

export const emailSignInStart = (emailAndAPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndAPassword,
})

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
})

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
})

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
})

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
})

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
})

export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData },
})

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
})

// ------ Fetch All Items Actions ------ //
export const fetchUsersStart = (usersMap) => ({
  type: UserActionTypes.FETCH_USERS_START,
})

export const fetchUsersSuccess = (usersMap) => ({
  type: UserActionTypes.FETCH_USERS_SUCCESS,
  payload: usersMap,
})

export const fetchUsersFailure = (errorMessage) => ({
  type: UserActionTypes.FETCH_USERS_FAILURE,
  payload: errorMessage,
})

export const fetchUsersStartAsync = () => {
  return (dispatch) => {
    const userRef = firestore.collection('users')
    dispatch(fetchUsersStart())

    userRef
      .get()
      .then((snapshop) => {
        const usersMap = convertUsersSnapshotToMap(snapshop)
        dispatch(fetchUsersSuccess(usersMap))
      })
      .catch((error) => dispatch(fetchUsersFailure(error.message)))
  }
}
