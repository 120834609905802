import { createSelector } from 'reselect'

const selectInspection = (state) => state.inspection

export const selectInspectionMap = createSelector(
  [selectInspection],
  (inspection) => inspection.inspectionsMap
)

export const selectInspectionItem = createSelector(
  [selectInspection],
  (inspection) => inspection.inspectionItem
)
