import InspectionActionTypes from './inspection.types'

const INITIAL_STATE = {
  inspectionItem: null,
  inspectionsMap: [],
  isFetching: false,
  error: null,
}

const inspectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InspectionActionTypes.FETCH_INSPECTIONS_START:
      return {
        ...state,
        isFetching: true,
      }
    case InspectionActionTypes.FETCH_INSPECTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        inspectionsMap: action.payload,
      }
    case InspectionActionTypes.FETCH_INSPECTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case InspectionActionTypes.FETCH_INSPECTION_START:
    case InspectionActionTypes.CREATE_INSPECTION_START:
    case InspectionActionTypes.UPDATE_INSPECTION_START:
    case InspectionActionTypes.DELETE_INSPECTION_START:
      return {
        ...state,
        inspectionItem: action.payload,
        error: null,
      }
    case InspectionActionTypes.FETCH_INSPECTION_SUCCESS:
    case InspectionActionTypes.CREATE_INSPECTION_SUCCESS:
    case InspectionActionTypes.UPDATE_INSPECTION_SUCCESS:
    case InspectionActionTypes.DELETE_INSPECTION_SUCCESS:
      return {
        ...state,
        inspectionItem: action.payload,
        error: null,
      }
    case InspectionActionTypes.FETCH_INSPECTION_FAILURE:
    case InspectionActionTypes.CREATE_INSPECTION_FAILURE:
    case InspectionActionTypes.UPDATE_INSPECTION_FAILURE:
    case InspectionActionTypes.DELETE_INSPECTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default inspectionReducer
