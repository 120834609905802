import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import CustomButton from '../../components/custom-button/custom-button.component'
import RequestInspectionForm from '../../forms/request-inspection/request-inspection'

import './realtors.styles.scss'
import RealtorsContent from '../../assets/content-realtors.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    top: 8,
    bottom: 8,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 3),
    margin: theme.spacing(8, 4),
    overflow: 'auto',
  },
}))

export default function Realtors() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <InternalPageHero heroClass="realtors-hero" title="Realtors" />
      <InternalPageContent rightImage={RealtorsContent}>
        <p className="int-p">
          A realtor’s worst nightmare is having a deal collapse when it looks like
          everything is aligning perfectly, especially when the collapse is
          completely or even partly due to the late results of an inspection. At
          Already Inspected, we remove that uncertainty from the equation.
        </p>
        <h3>How We Help Realtors</h3>
        <p className="int-p">
          Happy buyers and sellers make happy realtors, because the negotiation
          process can be so much simpler when everyone is on the same page. Even
          though everything might be going well initially, a bad inspection can
          change things drastically, possibly leading to the buyer walking away from
          the negotiation table.
        </p>
        <p className="int-p">
          With a pre-listing inspection through Already Inspected, potential issues
          can be taken care of before a deal even begins to come together. The buyer
          will have upfront information and the deal can move along without the worry
          of late inspection results.
        </p>
        <h3>Benefits for Realtors</h3>
        <p className="int-p">
          Having a successful and satisfactory deal on all sides is a huge
          accomplishment. Coming to us before the discussions start will make that
          accomplishment a lot easier to achieve. Let’s take a look at some of the
          benefits you’ll notice.
        </p>
        <ul>
          <li className="int-p">
            Sales will be completed faster, meaning more sales for you in the long
            run.
          </li>
          <li className="int-p">
            There is less of a chance you’ll lose money spent on marketing due to the
            inspection contingency clause or an unsuccessful repairs negotiation.
          </li>
          <li className="int-p">
            You can avoid reputational damage due to issues caused by an inspection.
          </li>
        </ul>
        <h3>Ready to Complete Sales Fast and Efficiently?</h3>
        <p className="p-50">
          Having a deal fall through at the last second can be very costly, which is
          why working with us to get an early pre-listing inspection can eliminate
          the common cause for these occurrences. We want to help the sales process
          to be as stress-free as possible. Contact us today to learn more!
        </p>
        <CustomButton onClick={handleOpen}>Request an Inspection</CustomButton>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <RequestInspectionForm />
          </div>
        </Modal>
      </InternalPageContent>
    </>
  )
}
