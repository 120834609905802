import UserActionTypes from './user.types'

const INITIAL_STATE = {
  currentUser: null,
  usersMap: [],
  isFetching: false,
  error: null,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
      }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
      }
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case UserActionTypes.FETCH_USERS_START:
      return {
        ...state,
        isFetching: true,
      }
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        usersMap: action.payload,
      }
    case UserActionTypes.FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
