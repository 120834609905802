import React from 'react'
import NavLogo from '../../assets/logo.png'
import { Link } from 'react-router-dom'

import './nav-brand.styles.scss'

export default function NavBrand() {
  return (
    <div className="nav-brand">
      <Link to="/">
        <img className="nav-logo" src={NavLogo} alt="Navbar Logo" />
      </Link>
    </div>
  )
}
