import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List, ListItem, ListItemText, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export default function Review({ report, handleNext, handleBack }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem} key={report.docData.streetAddress}>
          <ListItemText
            primary={`${report.docData.streetAddress}, ${report.docData.aptUnitNumber}`}
            secondary={`${report.docData.city}, ${report.docData.state} ${report.docData.zipCode}`}
          />
          <Typography variant="body2">$49.00</Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            $49.00
          </Typography>
        </ListItem>
      </List>
      <br />
      <br />
      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  )
}
