import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import './thank-you.styles.scss'
import CustomButton from '../../components/custom-button/custom-button.component'

const ThankYou = () => {
  return (
    <div className="thank-you">
      <Box component="div" m={8}>
        <h1 className="h1-ty">Thank You</h1>
        <div className="black-bg">
          <h4>Your message has been received!</h4>
          <p>We will be contacting you shortly to follow-up.</p>
        </div>
      </Box>
      <Box component="div" m={1} className="btns-ty">
        <CustomButton>
          <Link to="/">Go Home</Link>
        </CustomButton>
      </Box>
    </div>
  )
}
export default ThankYou
