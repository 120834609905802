import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { storage, filesDb, inspectionsDb } from '../../firebase/firebase.utils'
import { v4 as uuidv4 } from 'uuid'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import EditIcon from '@material-ui/icons/Edit'
import { DropzoneArea } from 'material-ui-dropzone'

import './inspection-modal-form.styles.scss'
import CustomButton from '../../components/custom-button/custom-button.component'

import {
  createInspectionsSuccess,
  updateInspectionSuccess,
} from '../../redux/inspection/inspection.actions'
import { selectInspectionItem } from '../../redux/inspection/inspection.selector'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none !important',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const InspectionModalForm = ({
  createInspectionsSuccess,
  updateInspectionSuccess,
  user,
  callBack,
}) => {
  const classes = useStyles()
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [imageAsFile, setImageAsFile] = useState([])
  const [inspectionItem, setInspectionItem] = useState({
    streetAddress: '',
    aptUnitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    skuNumber: '',
    latitude: '',
    longitude: '',
  })

  const {
    streetAddress,
    aptUnitNumber,
    city,
    state,
    zipCode,
    skuNumber,
    latitude,
    longitude,
  } = inspectionItem
  const userId = user.currentUser.id
  const createdAt = new Date()

  const inspectionItemStore = useSelector(selectInspectionItem)
  useEffect(() => {
    if (inspectionItemStore !== null && params.id) {
      setInspectionItem({
        streetAddress: inspectionItemStore.docData.streetAddress,
        aptUnitNumber: inspectionItemStore.docData.aptUnitNumber,
        city: inspectionItemStore.docData.city,
        state: inspectionItemStore.docData.state,
        zipCode: inspectionItemStore.docData.zipCode,
        files: inspectionItemStore.docData.files,
        skuNumber: inspectionItemStore.docData.skuNumber,
        latitude: inspectionItemStore.docData.latitude,
        longitude: inspectionItemStore.docData.longitude,
      })
    }
  }, [inspectionItemStore])

  const onDrop = (files) => {
    const image = files
    setImageAsFile((imageAsFile) => image)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const clearForm = () => {
    setInspectionItem({
      streetAddress: '',
      aptUnitNumber: '',
      city: '',
      state: '',
      zipCode: '',
      files: '',
      skuNumber: '',
      latitude: '',
      longitude: '',
    })
  }

  const reloadPage = () => {
    window.location.reload()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (imageAsFile === []) {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`)
    }

    // Check if the doc already exist
    if (params.id) {
      const inspectionRef = inspectionsDb.doc(params.id)
      try {
        await inspectionRef.get().then(async (doc) => {
          if (doc.exists) {
            imageAsFile.map((imgAsFile) => {
              const uploadTask = storage
                .ref(`/files/${imgAsFile.name}`)
                .put(imgAsFile) // TODO: create method to update file in storage
              uploadTask.on(
                'state_changed',
                (snapShot) => {
                  console.log(snapShot)
                },
                (err) => {
                  console.log(err)
                },
                () => {
                  storage
                    .ref('files')
                    .child(imgAsFile.name)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                      filesDb
                        .where('inspectionId', '==', doc.data().inspectionId)
                        .then((files) => {
                          files.forEach((file) => {
                            file.update({ url: fireBaseUrl })
                          })
                        })
                    })
                }
              )
              return uploadTask
            })

            await updateInspectionSuccess(
              params.id,
              streetAddress,
              aptUnitNumber,
              city,
              state,
              zipCode,
              skuNumber,
              latitude,
              longitude,
              createdAt,
              userId
            )
              .then(() => clearForm())
              .then(() => handleClose())
              .then(() => reloadPage())
          } else {
            console.log('No such doc inspection')
          }
        })
      } catch (error) {
        console.log('error updating inspection item', error.message)
      }
    } else {
      try {
        const inspectionId = uuidv4()
        await imageAsFile.map((imgAsFile) => {
          const uploadTask = storage.ref(`/files/${imgAsFile.name}`).put(imgAsFile)
          uploadTask.on(
            'state_changed',
            (snapShot) => {
              console.log(snapShot)
            },
            (err) => {
              console.log(err)
            },
            () => {
              storage
                .ref('files')
                .child(imgAsFile.name)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  filesDb.add({
                    inspectionId: inspectionId,
                    url: fireBaseUrl,
                  })
                })
            }
          )
          return uploadTask
        })

        await createInspectionsSuccess(
          streetAddress,
          aptUnitNumber,
          city,
          state,
          zipCode,
          skuNumber,
          latitude,
          longitude,
          createdAt,
          inspectionId,
          userId
        )
          .then(() => clearForm())
          .then(() => handleClose())
          .then(() => callBack(true))
      } catch (error) {
        console.log('error creating inspection item', error.message)
      }
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setInspectionItem({ ...inspectionItem, [name]: value })
  }

  const handleChangeNumber = (event) => {
    const { name, value } = event.target
    setInspectionItem({ ...inspectionItem, [name]: parseInt(value) })
  }

  const handleChangeFloat = (event) => {
    const { name, value } = event.target
    setInspectionItem({ ...inspectionItem, [name]: parseFloat(value) })
  }

  return (
    <div>
      <Button
        variant="contained"
        className="add-report"
        startIcon={!params.id ? <CloudUploadIcon /> : <EditIcon />}
        onClick={handleOpen}
      >
        {!params.id ? 'Add Report' : 'Edit Report'}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid container className={classes.paper} item xs={12} sm={6} md={4}>
            <Avatar className="avatar-icon">
              <NoteAddIcon className="cloud-icon" />
            </Avatar>
            <h6 className="form-title">
              {!params.id ? 'Create New Inspection' : 'Edit Inspection'}
            </h6>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="streetAddress"
                    name="streetAddress"
                    variant="outlined"
                    required
                    fullWidth
                    id="streetAddress"
                    label="Street Address"
                    autoFocus
                    value={streetAddress}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="aptUnitNumber"
                    label="Apt/Unit Number"
                    autoFocus
                    name="aptUnitNumber"
                    autoComplete="aptUnitNumber"
                    value={aptUnitNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                    value={city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="state"
                    label="State"
                    id="state"
                    autoComplete="state"
                    value={state}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="zipCode"
                    label="Zip Code"
                    id="zipCode"
                    type="number"
                    autoComplete="zipCode"
                    value={zipCode}
                    onChange={handleChangeNumber}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="skuNumber"
                    label="SKU"
                    id="skuNumber"
                    autoComplete="skuNumber"
                    value={skuNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="latitude"
                    label="Latitude"
                    id="latitude"
                    type="text"
                    autoComplete="latitude"
                    value={latitude}
                    onChange={handleChangeFloat}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="longitude"
                    label="Longitude"
                    id="longitude"
                    type="text"
                    autoComplete="longitude"
                    value={longitude}
                    onChange={handleChangeFloat}
                  />
                </Grid>
                <Grid item xs={12}>
                  <a
                    href="https://www.google.com/maps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find the latitude and longitude in Google Map
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <DropzoneArea
                    label="Files type accepted: pdf, jpeg, png. File size limit: 5MB"
                    name="file"
                    id="file"
                    onChange={onDrop}
                    acceptedFiles={[
                      'image/jpeg',
                      'image/png',
                      'image/bmp',
                      'application/pdf',
                    ]}
                    showPreviews={false}
                    maxFileSize={5000000}
                    renameFile="Inspection-Docs"
                  />
                </Grid>
              </Grid>
              <CustomButton type="submit" isSubmit>
                Submit
              </CustomButton>
            </form>
          </Grid>
        </Fade>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  createInspectionsSuccess: (...inspectionItem) =>
    dispatch(createInspectionsSuccess(inspectionItem)),
  updateInspectionSuccess: (...inspectionItem) =>
    dispatch(updateInspectionSuccess(inspectionItem)),
})

export default connect(
  (store) => ({ user: store.user }),
  mapDispatchToProps
)(InspectionModalForm)
