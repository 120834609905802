import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import './mobile.sidebar.styles.scss'

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}))

const MobileSidebar = ({ currentUser }) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    left: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <NavLink className="nav-link" activeClassName="is-active" to="/">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </NavLink>
      </List>
      <Divider />
      <List>
        <NavLink className="nav-link" activeClassName="is-active" to="/about">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </NavLink>
        <NavLink className="nav-link" activeClassName="is-active" to="/find-report">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Find Report" />
          </ListItem>
        </NavLink>
        <NavLink className="nav-link" activeClassName="is-active" to="/buyers">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Buyers" />
          </ListItem>
        </NavLink>
        <NavLink className="nav-link" activeClassName="is-active" to="/sellers">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Sellers" />
          </ListItem>
        </NavLink>
        <NavLink className="nav-link" activeClassName="is-active" to="/realtors">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Realtors" />
          </ListItem>
        </NavLink>
        {currentUser ? (
          <NavLink className="nav-link" activeClassName="is-active" to="/inspectors">
            <ListItem button>
              <ListItemIcon>
                <DoubleArrowIcon className="icon-red" />
              </ListItemIcon>
              <ListItemText primary="Inspectors" />
            </ListItem>
          </NavLink>
        ) : (
          <NavLink className="nav-link" activeClassName="is-active" to="/directory">
            <ListItem button>
              <ListItemIcon>
                <DoubleArrowIcon className="icon-red" />
              </ListItemIcon>
              <ListItemText primary="Directory" />
            </ListItem>
          </NavLink>
        )}
        <NavLink className="nav-link" activeClassName="is-active" to="/contractors">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Contractors" />
          </ListItem>
        </NavLink>
      </List>
      <Divider />
      <List>
        <NavLink className="nav-link" activeClassName="is-active" to="/blog">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItem>
        </NavLink>
        <NavLink className="nav-link" activeClassName="is-active" to="/contact-us">
          <ListItem button>
            <ListItemIcon>
              <DoubleArrowIcon className="icon-red" />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
        </NavLink>
      </List>
    </div>
  )

  return (
    <div className="mobile-sidebar">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(anchor, true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStateToProps)(MobileSidebar)
