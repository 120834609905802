import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from './redux/user/user.selector'
import { checkUserSession } from './redux/user/user.action'

import './scss/_design.scss'

import TopBar from './components/topbar/topbar.component'
import Navbar from './components/navbar/navbar.component'
import Footer from './components/footer/footer.component'
import HomePage from './pages/home/home.page'
import Login from './e-commerce/login/login'
import Register from './e-commerce/register/register'
import PasswordReset from './e-commerce/reset/reset'
import About from './pages/about/about.page'
import Buyers from './pages/buyers/buyers.page'
import Sellers from './pages/sellers/sellers.page'
import Realtors from './pages/realtors/realtors.page'
import Inspectors from './pages/inspectors/inspectors.page'
import InspectionDetails from './pages/inpection-details/inspection-details.page'
import InspectorsDirectory from './pages/directory/directory.page'
import FindReports from './pages/find-reports/find-reports.page'
import Contractors from './pages/contractors/contractors.page'
import BlogPage from './pages/blog/blog.page'
import ContactUs from './pages/contact-us/contact-us.page'
import ThankYou from './pages/thank-you/thank-you.page'
import PrivacyNotice from './pages/privacy-notice/privacy-notice.page'
import TermsConditions from './pages/terms-conditions/term-conditions.page'

import PageNotFound from './pages/404/404.page'

import Dashboard from './dashboard/Dashboard'
import AdminLogin from './dashboard/AdminLogin'

const App = ({ checkUserSession, currentUser, location }) => {
  useEffect(() => {
    checkUserSession()
  }, [checkUserSession])

  const history = createBrowserHistory()

  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.initialize('UA-135210885-11')
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a pageview for the given page
  })

  return (
    <Router history={history}>
      <div>
        {location.pathname !== '/dashboard' ? <TopBar /> : ''}
        {location.pathname !== '/dashboard' ? <Navbar /> : ''}

        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route
            path="/login"
            exact
            render={() => (currentUser ? <Redirect to="/" /> : <Login />)}
          />
          <Route path="/register" exact component={Register} />
          <Route path="/reset-password" exact component={PasswordReset} />
          <Route path="/about" exact component={About} />
          <Route path="/buyers" exact component={Buyers} />
          <Route path="/sellers" exact component={Sellers} />
          <Route path="/realtors" exact component={Realtors} />
          <Route
            path="/inspectors"
            exact
            render={() => (currentUser ? <Inspectors /> : <Login />)}
          />
          <Route path="/inspectors/:id" exact component={InspectionDetails} />
          <Route path="/directory" exact component={InspectorsDirectory} />
          <Route path="/contractors" exact component={Contractors} />
          <Route path="/find-report" exact component={FindReports} />
          <Route path="/blog" exact component={BlogPage} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/thank-you" exact component={ThankYou} />
          <Route path="/privacy-notice" exact component={PrivacyNotice} />
          <Route path="/terms-conditions" exact component={TermsConditions} />
          <Route
            path="/dashboard"
            exact
            render={() => (currentUser ? <Dashboard /> : <AdminLogin />)}
          />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route component={PageNotFound} />
        </Switch>
        {location.pathname !== '/dashboard' ? <Footer /> : ''}
      </div>
    </Router>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
