import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { checkUserSession } from '../../redux/user/user.action'
import { fetchInspectionByIdSuccess } from '../../redux/inspection/inspection.actions'
import { selectInspectionItem } from '../../redux/inspection/inspection.selector'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Divider from '@material-ui/core/Divider'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LinkMui from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'

import './inspection-details.styles.scss'
import InspectorsContent from '../../assets/inspectors-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'
import InspectionModalForm from '../../components/inspection-modal-form/inspection-modal-form.component'
import Spinner from '../../components/spinner/spinner.component'
import Checkout from '../../e-commerce/checkout/Checkout'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#fcfcfc',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    height: 140,
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    transition: 'all ease .3s',

    '&:hover': {
      transform: 'translateY(10px)',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
}))

const InspectionDetails = ({
  checkUserSession,
  currentUser,
  fetchInspectionByIdSuccess,
}) => {
  const classes = useStyles()
  const params = useParams()
  //Reload and Spinner
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const inspectionItem = useSelector(selectInspectionItem)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1200)

    checkUserSession()
    fetchInspectionByIdSuccess(params.id)
  }, [checkUserSession, fetchInspectionByIdSuccess, params.id])

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <InternalPageHero heroClass="inspectors-hero" title="Inspection" />
      <InternalPageContent rightImage={InspectorsContent}>
        <Grid item xs={12}>
          <div className="list">
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="inspection-item-container">
                <Avatar className="inspection-icon">
                  <AssignmentIcon className="desc-icon" />
                </Avatar>
                <h5 className="item-title">
                  <span className="h5-strong">Inspection Report</span>
                </h5>
                <h6>
                  <span className="h6-strong-lower">Property Code:</span>
                </h6>
                <Divider />
                <p className="p-last">
                  <span className="p-strong">SKU Number: </span>
                  {inspectionItem.docData.skuNumber}
                </p>
                <h6>
                  <span className="h6-strong-lower">Property Information:</span>
                </h6>
                <Divider />
                <p>
                  <span className="p-strong">Street Address: </span>
                  {inspectionItem.docData.streetAddress}
                </p>
                <p>
                  <span className="p-strong">Apt/Unit Number: </span>
                  {inspectionItem.docData.aptUnitNumber}
                </p>
                <p>
                  <span className="p-strong">City: </span>
                  {inspectionItem.docData.city}
                </p>
                <p>
                  <span className="p-strong">State: </span>
                  {inspectionItem.docData.state}
                </p>
                <p className="p-last">
                  <span className="p-strong">Zip Code: </span>
                  {inspectionItem.docData.zipCode}
                </p>
                <h6>
                  <span className="h6-strong-lower">Buy Inspection Report:</span>
                </h6>
                <Divider />
                <Grid container className={classes.root} direction="row" spacing={2}>
                  {inspectionItem.fileUrls.map((url, index) => (
                    <Grid item xs={3} key={index}>
                      <Paper className={classes.paper}>
                        {currentUser ? (
                          <LinkMui
                            href={url}
                            target="_blank"
                            className="inline-link"
                          >
                            <Avatar className="file">
                              <AttachFileIcon className="file-icon" />
                            </Avatar>
                            <p>
                              Download
                              <br />
                              <span className="p-strong">Report</span>
                            </p>
                          </LinkMui>
                        ) : (
                          <>
                            <div onClick={handleOpen} className="inline-link">
                              <Avatar className="file">
                                <AttachFileIcon className="file-icon" />
                              </Avatar>
                              <p>
                                Download
                                <br />
                                <span className="p-strong">Report</span>
                              </p>
                            </div>
                            <Modal
                              className={classes.modal}
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="simple-modal-title"
                              aria-describedby="simple-modal-description"
                            >
                              <>
                                <Checkout report={inspectionItem} />
                              </>
                            </Modal>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                {currentUser ? <InspectionModalForm /> : ''}
                <Divider />
                {currentUser ? (
                  <Link to="/inspectors">
                    <Avatar className="avatar link-back">
                      <ArrowBackIcon className="folder-icon" />
                    </Avatar>
                  </Link>
                ) : (
                  <Link to="/find-report">
                    <Avatar className="avatar link-back">
                      <ArrowBackIcon className="folder-icon" />
                    </Avatar>
                  </Link>
                )}
              </div>
            )}
          </div>
        </Grid>
      </InternalPageContent>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
  fetchInspectionByIdSuccess: (id) => dispatch(fetchInspectionByIdSuccess(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetails)
