import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import * as emailjs from 'emailjs-com'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  error: {
    color: '#c23d4b',
    margin: '5px 0',
  },
}))

export default function PaymentForm({ handleBack, report, orderData, handleNext }) {
  const classes = useStyles()
  const stripePromise = loadStripe(
    'pk_live_51HcBYkHkQB775PWSIErW8MsHq5pJp2um9iWjI43LN8cZ8NZo4OGyN0LTJpfU0NPIBrZH21uTE3LcHc2M3yHZRp7s00Li5JeFOS'
  )
  const priceForStripe = 49.99 * 100

  const onToken = (token) => {
    const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/payment'
    axios({
      url: baseURL,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        amount: priceForStripe,
        token,
      },
    })
      .then((response) => {
        console.log('RESPONSE: ', response)
        alert('Payment successful')
        handleNext()
      })
      .catch((error) => {
        console.log('Payment error: ', error.response.data.error.message)
        alert(
          `There was an issue with your payment. ${error.response.data.error.message}`
        )
      })
  }

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault()

    if (!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)
    const result = await stripe.createToken(cardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log(error.message)
      setErrorMessage(error.message)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      onToken(result.token)

      setErrorMessage('')

      let templateParams = {
        from_name: 'Already Inspected',
        to_name: orderData.firstName + ' ' + orderData.lastName,
        to_email: orderData.email,
        subject: 'Report Link',
        message_html: `Download report: ${report.fileUrls[0]}`,
      }
      emailjs.send(
        'service_elhi7fz',
        'template_097lqal',
        templateParams,
        'user_BOQwR7nOannjbRYcGLmHU'
      )
    }
  }

  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = ({ error }) => {
    if (error) {
      setErrorMessage(error.message)
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
              <br />
              <CardElement onChange={handleChange} />
              <br />
              <div className={classes.error} role="alert">
                {errorMessage}
              </div>
              <br />
              <div className={classes.buttons}>
                <Button onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!stripe}
                  color="primary"
                  className={classes.button}
                >
                  Place order
                </Button>
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    </React.Fragment>
  )
}
