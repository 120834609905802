import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from '../../components/custom-button/custom-button.component'

import './buyers.styles.scss'
import BuyersContent from '../../assets/buyers-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'

export default function Buyers() {
  return (
    <>
      <InternalPageHero heroClass="buyers-hero" title="Buyers" />
      <InternalPageContent rightImage={BuyersContent}>
        <p className="int-p">
          Searching for a new home can be an exhausting undertaking whether it's your
          first purchase or you're an experienced buyer.
        </p>
        <h3>How We Help Buyers</h3>
        <p className="int-p">
          When you’re looking to make a purchase, your goal is to find the perfect
          home. When you do, it feels like quite an accomplishment. The bedrooms and
          living spaces are perfect and very much to your liking. The backyard is
          spacious. The kitchen design is just the way you like it – but then
          disaster strikes. Upwards of $800 is spent on a Home Inspection only to
          find out that this might not be the right home for you after all. Just when
          you thought you were at the finish line, the deal falls through and all of
          that time was wasted.
        </p>
        <p className="int-p">
          With pre-listing inspections, buyers will have complete upfront information
          about the home and property, allowing you to make more informed buying
          decisions.
        </p>
        <h3>Benefits of Turning to Us</h3>
        <p className="int-p">
          When the buyer is happy, the entire home-selling and purchasing process is
          much easier and less stressful. Let’s take a look at some of the benefits
          you’ll experience when Already Inspected handles the inspection process.
        </p>
        <ul>
          <li className="int-p">
            There’s less of a chance that a deal gets killed in the final stages of a
            sale.
          </li>
          <li className="int-p">Upfront information means the buyer saves time.</li>
          <li className="int-p">
            The process of purchasing a home can be a lot faster.
          </li>
        </ul>
        <h3>Ready to Buy a Home Without All the Hassle? </h3>
        <p className="p-50">
          Not having to deal with inspections late in the game can put you at ease
          since you’ll know exactly what you’re getting yourself into. We know you
          want the best possible home for you and we’re here to help make that a
          reality. Contact us today to learn more!
        </p>
        <Link to="/find-report">
          <CustomButton isLarge>Find an Already-Inspected Home</CustomButton>
        </Link>
      </InternalPageContent>
    </>
  )
}
