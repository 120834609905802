import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { checkUserSession } from '../../redux/user/user.action'
import {
  fetchInspectionsStartAsync,
  deleteInspectionSuccess,
} from '../../redux/inspection/inspection.actions'
import { selectInspectionMap } from '../../redux/inspection/inspection.selector'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import FolderIcon from '@material-ui/icons/Folder'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Pagination from '@material-ui/lab/Pagination'
import Divider from '@material-ui/core/Divider'

import './inspectors.styles.scss'
import InspectorsContent from '../../assets/inspectors-content.jpg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import InternalPageContent from '../../components/internal-page-content/internal-page-content.component'
import InspectionModalForm from '../../components/inspection-modal-form/inspection-modal-form.component'
import Spinner from '../../components/spinner/spinner.component'

const Inspectors = ({
  checkUserSession,
  currentUser,
  fetchInspectionsStartAsync,
  deleteInspectionSuccess,
}) => {
  //Reload and Spinner
  const [isLoading, setIsLoading] = useState(true)
  const [reload, setReload] = useState(false)

  //Data
  const inspectionsMap = useSelector(selectInspectionMap)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1200)

    setReload(false)
    checkUserSession()
    fetchInspectionsStartAsync()
  }, [checkUserSession, fetchInspectionsStartAsync, reload])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [sliceBegin, setSliceBegin] = useState(0)
  const [sliceEnd, setSliceEnd] = useState(5)
  const maxPage = Math.ceil(Object.keys(inspectionsMap).length / 5)

  const handleChange = async (event, value) => {
    if (value === 1) {
      await setCurrentPage(value)
      await setSliceBegin(0)
      await setSliceEnd(5)
    } else if (value > 1) {
      await setCurrentPage(value)
      await setSliceBegin((value - 1) * 5)
      await setSliceEnd((value - 1) * 5 + 5)
    }
  }

  // Delete
  const deleteFunc = async (id, inspectionId) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this inspection item!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteInspectionSuccess(id, inspectionId)

          swal('Poof! Your inspection item has been deleted!', {
            icon: 'success',
          })
        } else {
          swal('Your inspection item is safe!')
        }
      })
      .then(() => {
        fetchInspectionsStartAsync()
      })
  }

  return (
    <>
      <InternalPageHero heroClass="inspectors-hero" title="Inspectors" />
      <InternalPageContent rightImage={InspectorsContent}>
        <p className="int-p">
          Making the home buying process easier is what we strive for, which is why
          we’re the place for everyone’s home inspection needs. Whether you’re a
          Buyer, Seller, Realtor®, Inspector, or Contractor, Already Inspected is the
          place to be.
        </p>
        <Divider className="divider" />
        <h3>Upload Inspection Report Here</h3>
        <p className="int-p">
          If you’re a buyer or realtor looking for a home inspector for a pre-listing
          inspection, take a look at these professional providers below.
        </p>

        <Grid item xs={12}>
          <div className="list">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <List>
                  <h6 className="page-number">Page {currentPage}</h6>
                  {Object.values(inspectionsMap)
                    .filter((inspecMap) => inspecMap.userId === currentUser.id)
                    .slice(sliceBegin, sliceEnd)
                    .map((inspection) => (
                      <ListItem className="list-item" key={inspection.id}>
                        <ListItemAvatar>
                          <Avatar className="avatar">
                            <FolderIcon className="folder-icon" />
                          </Avatar>
                        </ListItemAvatar>
                        <Link
                          to={`/inspectors/${inspection.id}`}
                          key={inspection.id}
                          className="inspection-link"
                        >
                          <p>{inspection.streetAddress}</p>
                        </Link>
                        <ListItemSecondaryAction className="delete-container">
                          {currentUser ? (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              className="delete-btn"
                              onClick={() =>
                                deleteFunc(inspection.id, inspection.inspectionId)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </>
            )}
            {currentUser ? <InspectionModalForm callBack={setReload} /> : ''}

            <Pagination count={maxPage} page={currentPage} onChange={handleChange} />
          </div>
        </Grid>

        <Divider className="divider" />
        <h3>For the Inspectors</h3>
        <h6 className="h6-strong">How We Help Inspectors</h6>
        <p className="int-p">
          By being listed on the Already Inspected list of inspectors, you will have
          a constant stream of new opportunities for your business. We want to make
          the inspection process easier for everyone involved, making home purchases
          smoother and more satisfactory than ever before. This is accomplished by
          getting sellers and real estate agents in direct contact with you for
          pre-listing inspections.
        </p>
        <h6 className="h6-strong">Benefits for Inspectors</h6>
        <p className="int-p">
          Pre-listing inspections will greatly benefit everyone involved with the
          home sale and purchase experience. No one will have to deal with the hassle
          of intense late negotiations due to inspection results which can possibly
          lead to a deal falling apart altogether. Due to the great benefits that
          come from having a pre-listing inspection, more people will come to us to
          find inspectors. We will guide them right to you, meaning more sellers and
          realtors. Here are the benefits you’ll notice when working with Already
          Inspected.
        </p>
        <ul>
          <li className="int-p">More customers</li>
          <li className="int-p">New avenue for getting pre-listing inspections</li>
          <li className="int-p">Easy way to get your company noticed</li>
        </ul>
        <h3>Ready for More Inspection Opportunities?</h3>
        <p className="int-p">
          Are you an inspection company looking for a better way to find more
          business? With Already Inspected, we can make that a reality. Contact us
          today to learn more.
        </p>
      </InternalPageContent>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
  fetchInspectionsStartAsync: (...inspectionsMap) =>
    dispatch(fetchInspectionsStartAsync(inspectionsMap)),
  deleteInspectionSuccess: (id, inspectionId) =>
    dispatch(deleteInspectionSuccess(id, inspectionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inspectors)
