import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import LayersIcon from '@material-ui/icons/Layers'
import AssignmentIcon from '@material-ui/icons/Assignment'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'

import './dashboard.scss'

export const mainListItems = (
  <div>
    <ListItem button className="list-item">
      <ListItemIcon>
        <DashboardIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <ShoppingCartIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <PeopleIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <PeopleIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Inspectors" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <PeopleIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Contractors" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <BarChartIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <SpeakerNotesIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Posts" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <LayersIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem>
  </div>
)

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button className="list-item">
      <ListItemIcon>
        <AssignmentIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <AssignmentIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button className="list-item">
      <ListItemIcon>
        <AssignmentIcon className="side-bar-icon" />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
)
