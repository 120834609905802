import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import AccountCircle from '@material-ui/icons/AccountCircle'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import RoomIcon from '@material-ui/icons/Room'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'

import './contact-us.styles.scss'
import CustomButton from '../../components/custom-button/custom-button.component'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#de2c2c',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  tile: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paperTile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    minHeight: 160,
    padding: theme.spacing(3),
    transition: 'all ease .3s',

    '&:hover': {
      transform: 'translateY(10px)',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
}))

const ContactUs = ({ history }) => {
  const classes = useStyles()

  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  })

  const { fullName, email, phone, message } = contactInfo

  const handleChange = (event) => {
    const { value, name } = event.target

    setContactInfo({ ...contactInfo, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)

    fetch('https://usebasin.com/f/11ed73f89210', {
      method: 'POST',
      body: data,
    })
    history.push('/thank-you')
  }

  return (
    <Grid container component="main" className="contact">
      <Grid item xs={false} sm={4} md={7} className="image" />
      <Grid item xs={12} sm={8} md={5} elevation={6} className="wrapper">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SendRoundedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Us
          </Typography>
          <p className="int-p">
            Ready to get started? Maybe you have a question for us? Please fill out
            the contact form below or give us a call!
          </p>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="subject"
                  name="subject"
                  autoComplete="subject"
                  autoFocus
                  value="Already Inspected - Contact Us Page Form"
                  type="hidden"
                  className="hidden"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  autoFocus
                  value={fullName}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle className="input-icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon className="input-icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  label="Phone Number"
                  type="phone"
                  id="phone"
                  autoComplete="current-phone"
                  value={phone}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIcon className="input-icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={4}
                  name="message"
                  label="Message/Question?"
                  id="message"
                  autoComplete="current-message"
                  value={message}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <CustomButton type="submit" isSubmit>
              Submit
            </CustomButton>
          </form>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Link href="mailto:info@already-inspected.com" underline="none">
                <Paper className={classes.paperTile} elevation={1}>
                  <Avatar className="avatar">
                    <EmailIcon className="contact-icon" />
                  </Avatar>
                  <p className="p-paper">info@already-inspected.com</p>
                </Paper>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Link href="tel:4072379905" underline="none">
                <Paper className={classes.paperTile} elevation={1}>
                  <Avatar className="avatar">
                    <PhoneIcon className="contact-icon" />
                  </Avatar>
                  <p className="p-paper">407.237.9905</p>
                </Paper>
              </Link>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Link href="#" underline="none">
                <Paper className={classes.paperTile} elevation={1}>
                  <Avatar className="avatar">
                    <RoomIcon className="contact-icon" />
                  </Avatar>
                  <p className="p-paper">Address Pending</p>
                </Paper>
              </Link>
            </Grid> */}
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default withRouter(ContactUs)
