import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Pagination from '@material-ui/lab/Pagination'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import swal from 'sweetalert'

import './directory.page.scss'
import InternalContentPath from '../../assets/benefit-path.svg'
import InternalPageHero from '../../components/internal-page-hero/internal-page-hero.component'
import AvatarPlaceholder from '../../assets/avatar-placeholder.png'
import Spinner from '../../components/spinner/spinner.component'
import { fetchUsersStartAsync } from '../../redux/user/user.action'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { checkUserSession } from '../../redux/user/user.action'
import { selectUserMap } from '../../redux/user/user.selector'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const InspectorsDirectory = ({ currentUser, fetchUsersStartAsync }) => {
  const classes = useRowStyles()
  // Accordion
  const [open, setOpen] = useState(-1)
  const handleClick = (i) => {
    console.log(i)
    const newIndex = open === i ? -1 : i
    setOpen(newIndex)
  }
  // Filters
  const [stateFilter, setStateFilter] = useState('')
  const [cityFilter, setCityFilter] = useState('')
  // Reload and Spinner
  const [reload, setReload] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // Data
  const usersMap = useSelector(selectUserMap)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1200)
    setReload(false)
    fetchUsersStartAsync()
  }, [reload, stateFilter, cityFilter, currentUser, fetchUsersStartAsync])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [sliceBegin, setSliceBegin] = useState(0)
  const [sliceEnd, setSliceEnd] = useState(5)
  const maxPage = Math.ceil(Object.keys(usersMap).length / 5)

  const handleChange = async (event, value) => {
    if (value === 1) {
      await setCurrentPage(value)
      await setSliceBegin(0)
      await setSliceEnd(5)
    } else if (value > 1) {
      await setCurrentPage(value)
      await setSliceBegin((value - 1) * 5)
      await setSliceEnd((value - 1) * 5 + 5)
    }
  }

  // Copied Notification
  const copiedNotification = () => {
    swal({
      title: 'Copied!',
      text: 'Insert this email into your request inspection form!',
      icon: 'success',
      button: 'Ok',
    })
  }

  return (
    <>
      <InternalPageHero heroClass="directory-hero" title="Inspectors Directory" />
      <div className="internal-content-directory">
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Box component="div" m={1}>
              <img
                className="content-path"
                src={InternalContentPath}
                alt="Internal Content Path"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Box component="div" m={1}>
              <Paper className="search-bar">
                <Grid container spacing={3} alignItems="center">
                  <Grid item sm={12} md={6}>
                    <h6 className="search-bar-title">Search Filters</h6>
                  </Grid>
                  <Grid item style={{ width: 300 }} sm={12} md={3}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(usersMap).map((user) => user.state)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setStateFilter(data)}
                    />
                  </Grid>
                  <Grid item style={{ width: 300 }} sm={12} md={3}>
                    <Autocomplete
                      freeSolo
                      className="input-search"
                      id="free-solo-2-demo"
                      disableClearable
                      options={Object.values(usersMap).map((user) => user.city)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City..."
                          margin="normal"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                      onInputChange={(e, data) => setCityFilter(data)}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <h6>Inspector</h6>
                          </TableCell>
                          <TableCell align="center">
                            <h6>Email</h6>
                          </TableCell>
                          <TableCell align="center">
                            <h6>Phone</h6>
                          </TableCell>
                          <TableCell align="center">
                            <h6>State</h6>
                          </TableCell>
                          <TableCell align="center">
                            <h6>City</h6>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stateFilter === '' && cityFilter === ''
                          ? Object.values(usersMap)
                              .slice(sliceBegin, sliceEnd)
                              .map((user, i) => (
                                <React.Fragment key={user.id}>
                                  <TableRow className={classes.root}>
                                    <TableCell>
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleClick(i)}
                                      >
                                        {open === i ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Avatar
                                        alt="Inspector Avatar"
                                        src={
                                          user.avatar === ''
                                            ? AvatarPlaceholder
                                            : user.avatar
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {user.firstName} {user.lastName}
                                    </TableCell>
                                    <TableCell align="center">
                                      <a
                                        className="email-link"
                                        href={`mailto:${user.email}`}
                                      >
                                        {user.email}
                                      </a>
                                      <CopyToClipboard
                                        text={user.email}
                                        onCopy={() => copiedNotification()}
                                      >
                                        <IconButton
                                          color="secondary"
                                          aria-label="upload picture"
                                          component="span"
                                          className="icon-button"
                                        >
                                          <FileCopyIcon className="file-copy" />
                                        </IconButton>
                                      </CopyToClipboard>
                                    </TableCell>
                                    <TableCell align="center">
                                      <a href={`tel:${user.phone}`}>{user.phone}</a>
                                    </TableCell>
                                    <TableCell align="center">
                                      {user.state}
                                    </TableCell>
                                    <TableCell align="center">{user.city}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{ paddingBottom: 0, paddingTop: 0 }}
                                      colSpan={7}
                                    >
                                      <Collapse
                                        in={open === i}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1} className="bio-container">
                                          <h4 className="h4-bio">Inspector Bio</h4>
                                          <p className="last-p">{user.bio}</p>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))
                          : Object.values(usersMap)
                              .filter(
                                (user) =>
                                  user.state === stateFilter ||
                                  user.city === cityFilter
                              )
                              .slice(sliceBegin, sliceEnd)
                              .map((user, i) => (
                                <React.Fragment key={user.id}>
                                  <TableRow className={classes.root}>
                                    <TableCell>
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleClick(i)}
                                      >
                                        {open === i ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Avatar
                                        alt="Inspector Avatar"
                                        src={
                                          user.avatar === ''
                                            ? AvatarPlaceholder
                                            : user.avatar
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {user.firstName} {user.lastName}
                                    </TableCell>
                                    <TableCell align="center">
                                      <a href={`mailto:${user.email}`}>
                                        {user.email}
                                      </a>
                                    </TableCell>
                                    <TableCell align="center">
                                      <a href={`tel:${user.phone}`}>{user.phone}</a>
                                    </TableCell>
                                    <TableCell align="center">
                                      {user.state}
                                    </TableCell>
                                    <TableCell align="center">{user.city}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{ paddingBottom: 0, paddingTop: 0 }}
                                      colSpan={7}
                                    >
                                      <Collapse
                                        in={open === i}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1} style={{ padding: 50 }}>
                                          <h4 className="h4-bio">Inspector Bio</h4>
                                          <p className="last-p">{user.bio}</p>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <Pagination
                count={maxPage}
                page={currentPage}
                onChange={handleChange}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
  fetchUsersStartAsync: (...inspectionsMap) =>
    dispatch(fetchUsersStartAsync(inspectionsMap)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectorsDirectory)
