import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import firebase from 'firebase/app'
import './reset.scss'
import CustomButton from '../../components/custom-button/custom-button.component'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#de2c2c',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

const PasswordReset = ({ history }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')

  const forgotPassword = (Email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(Email)
      .then(function () {
        alert('Please check your email...')
      })
      .then(function () {
        history.push('/login')
      })
      .catch(function (e) {
        console.log(e)
      })
  }

  return (
    <Grid container component="main" className="login">
      <Grid item xs={false} sm={4} md={7} className="image" />
      <Grid item xs={12} sm={8} md={5} elevation={6} className="wrapper">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomButton onClick={() => forgotPassword(email)} isSubmit>
                Submit
              </CustomButton>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Link className="redirect" to="/login">
                  Already have an account?
                </Link>
              </Grid>
              <Grid item>
                <Link className="redirect" to="/register">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default PasswordReset
