import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import './404.styles.scss'
import CustomButton from '../../components/custom-button/custom-button.component'

const PageNotFound = () => {
  return (
    <div className="not-found">
      <Box component="div" m={8}>
        <h1 className="h1-404">404</h1>
        <div className="black-bg">
          <h4>Page Not Found!</h4>
          <p>Sorry, the page you are looking for is not available.</p>
        </div>
      </Box>
      <Box component="div" m={1} className="btns-404">
        <CustomButton>
          <Link to="/">Go Home</Link>
        </CustomButton>
        <CustomButton isSecondary>
          <Link to="/contact-us">Contact Us</Link>
        </CustomButton>
      </Box>
    </div>
  )
}
export default PageNotFound
